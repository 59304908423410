import { useJson } from '../hooks';

const dataUrl = 'https://climb.wird.uk/resistances.json'

export const ResistanceTickFormat = () => {
    const { data, ...status } = useJson(dataUrl);

    if (status.isError) {
        return <pre>Error loading data</pre>
    }
    if (status.isLoading) {
        return <pre>Loading data...</pre>
    }
    // here we are passing on the json
    let resistanceTicks = []
    for (let i = 0; i < data.length; i++) {
        resistanceTicks.push(
            {
                name: data[i].name,
                description: data[i].description,
                range: data[i].range,
            })
    }
    return resistanceTicks;

}
// }
// 
// 
// console.log('tick value',{tickValue}, {data:data[i].range})
// if ((tickValue >= data[i].range[0]) && (tickValue <= data[i].range[1])) {
    // console.log('match')
// 
// return {name: data[i].name, 
    // description: data[i].description};