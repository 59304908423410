// https://travishorn.com/d3-line-chart-with-forecast-90507cb27ef2
// this tutorial helped with this

import { line, curveMonotoneX } from 'd3';
import { useContext } from 'react';
import { DataVarsContext, RegressionContext } from 'src/Context';




const predict = (data, newX, xValue, yValue) => {
    const round = n => Math.round(n * 100) / 100;

    const sum = data.reduce((acc, d) => {
        // console.log(xValue(d), yValue(d))
        const x = xValue(d);;
        const y = yValue(d);
        if (y !== null) {
            acc.x += x;
            acc.y += y;
            acc.squareX += x * x;
            acc.product += x * y;
            acc.len += 1;
        }
        return acc;
    }, { x: 0, y: 0, squareX: 0, product: 0, len: 0 });
    const rise = (sum.len * sum.product) - (sum.x * sum.y);
    const run = (sum.len * sum.squareX) - (sum.x * sum.x);
    const gradient = run === 0 ? 0 : round(rise / run);
    const intercept = round(
        (sum.y / sum.len) - ((gradient * sum.x) / sum.len));
    return round((gradient * newX) + intercept);
}

export const RegressionForecast = ({
    data,
    xScale,
    yScale,
}) => {
    // const data = useContext(RegressionContext)
    const { xValue, xField, yValue, yField, pointValue, pointName } = useContext(DataVarsContext)
    if (data) {
        let forecast = [{ [xField]: -5 }, { [xField]: 99 },];
        forecast = forecast.map((d, i) => {
            return {
                [xField]: xValue(d),
                [yField]: predict(data, xValue(d), xValue, yValue),
            }
        })
        let classDef = `grade${pointValue(data[0])}`

        const linePath = line()
        .x((d) => xScale(xValue(d)))
        .y((d) => yScale(yValue(d)))
        .curve(curveMonotoneX)(forecast);
        console.log(forecast)

        return (
            <svg>
                <path className={`${classDef} forecast`} d={linePath} />
                <text className={classDef} transform={`translate(10, ${yScale(yValue(forecast[0]))})`}>{pointValue(data[0])}</text>
                {/* <text className={classDef} transform={`translate(400, ${yScale(yValue(forecast[1]))})`}>{pointValue(data[0])}</text> */}
            </svg >
        )
    } return <></>

}
// {xScale(xValue(10))