import { formatGrade } from "../GradeScaleSelection"
import { useMemo, useState } from "react"
import { useContext } from 'react';
import { DataContext, GradeContext, HoveredContext } from 'src/Context';
import { CollapsibleComponent } from "../CollapseToggle";
import { Search } from "../AddDataApp/AutoComplete";
import { GiBiceps } from "react-icons/gi";


export const SelectedLabel = () => {

    const [selectedScale, setGradeScale] = useContext(GradeContext);
    const [data, setData] = useContext(DataContext);
    const [route, setHoveredValue] = useContext(HoveredContext);

    const [logs, setLogs] = useState(null)
    const getMoreDetails = () => {
        console.log('setting', data.route_logs, route._id)
        setLogs({
            'routeLogs': data.route_logs.filter(d => d.route_id === route._id),
            'area': data.crags.filter(c => route.crag_id === c._id)[0],
        })
    }

    const userLabel = ({ user_id }) => {
        let user = data.users.filter(u => u._id === user_id)[0];
        return `${user.first_name} ${user.last_name}`
    }
    // console.log({ logs }, logs.length)
    if (logs && route) {
        console.log('reset', { logs }, logs)
        if (route._id !== logs.routeLogs[0].route_id) {
            setLogs(null)
        }
    }
    const BoxLabel = useMemo(() =>
        route && route._id ?
            `${route.route_name}, ${formatGrade(route.average_grade, selectedScale)}`
            : 'Highlight a route'
        , [route, selectedScale])

    const handleSearch = e => {
        if (e.matchFound) {
            data.routes.map(r => {
                if (r._id === e.matchFound) {
                    return setHoveredValue(r)
                } return 0
            })
        }
    }

    return (
        <CollapsibleComponent title={BoxLabel}>
            <div className="flex flex-row gap-2 sm:gap-5 p-2 sm:p-5">
                <h1 className="sm:min-w-max">Search Routes:</h1>
                <div className="flex flex-col w-full">
                    <Search
                    suggestions={data.routes}
                    fieldName={'route_name'}
                    value={route && route._id ?
                        route.route_name : ""}
                        handleChange={e => handleSearch(e)}
                        />
                        </div>
            </div>

            {route && route._id ?
                <div className='w-auto items-center flex flex-row justify-center'>
                    <table className="w-full items-stretch">
                        <thead className="text-xs sm:text-md">
                            <tr className="font-semibold">
                                <td>Route:</td>
                                <td>Grade:</td>
                                <td>Resi:</td>
                                <td>Crux:</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="basis-1/4 capitalize">
                                    {route.route_name}
                                </td>
                                <td className="basis-1/4">
                                    {formatGrade(route.average_grade, selectedScale)}
                                </td>
                                <td className="basis-1/4 flex flex-row gap-2">
                                    {route.average_res} <GiBiceps/>
                                </td>
                                <td className="basis-1/4">
                                    v{route.average_crux}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                : null}
            <CollapsibleComponent title='More Details' action={getMoreDetails}>
                {logs ?
                    <div
                        className="flex flex-col items-center gap-2 justify-center w-full mt-5 text-left"
                    >
                        <p>Logged by {logs.routeLogs.length} user{logs.routeLogs.length > 1 ? "s" : ""}</p>
                        <p className="capitalize"><strong>Location:</strong> {logs.area.crag_name}, {logs.area.country}.</p>
                        <h3
                            className="font-bold"
                        >Comments</h3>
                        {/* should be table */}
                        <div className="flex flex-col justify-between items-left w-full">
                            {logs.routeLogs.map((d) => {
                                return (
                                    <div
                                        className="flex flex-row align-baseline items-stretch justify-between"
                                        key={d._id}
                                    >
                                        <p
                                            className="font-semibold capitalize"
                                        >{userLabel(d)}</p>
                                        <p
                                            className=""
                                        >{d.comment ? d.comment : "no comment"}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    : <></>}
            </CollapsibleComponent>
        </CollapsibleComponent>
    )
}
