import { formatGrade } from '../GradeScaleSelection';
import { useContext, useRef } from 'react';
import { GradeContext } from 'src/Context';
import { scaleLinear } from 'd3';

const width = 100;
const height = 20;

export const DoubleSlider = ({
    fieldName,
    description,
    selectedRange,
    setSelectedRange,
    dataRange,
    fields = ['min', 'max'],
    step = 1,

}) => {
    const [selectedScale, setSelectedScale] = useContext(GradeContext);
    const sliderRef = useRef();


    const setMax = val => {
        setSelectedRange(current => {
            return {
                ...current,
                max: +val
            }
        })
    }
    const setMin = val => {
        setSelectedRange(current => {
            return {
                ...current,
                min: +val
            }
        })
    }

    const sliderScale = scaleLinear()
        .domain([dataRange.min, dataRange.max])
        .range([0, width])
        .nice()


    const handleLineClick = e => {
        const viewWidth = sliderRef.current.offsetWidth
        const sliderX = sliderRef.current.offsetLeft;
        const x = ((e.pageX - sliderX) / viewWidth) * width;
        const gradePicked = 
        fieldName === 'crux' ? 
        Math.round(sliderScale.invert(x) * 10) / 10
        :
        Math.round(sliderScale.invert(x))
        if (gradePicked > selectedRange.max) {
            setMax(gradePicked)
            return gradePicked;
        } else if (gradePicked < selectedRange.min) {
            setMin(gradePicked)
            return gradePicked;
        }
        const midSelection = selectedRange.max - ((selectedRange.max - selectedRange.min) / 2)
        if (gradePicked < selectedRange.max && gradePicked >= midSelection) {
            setMax(gradePicked)
            return gradePicked;
        } else if (gradePicked > selectedRange.min && gradePicked <= midSelection) {
            setMin(gradePicked)
            return gradePicked;
        }
    }

    return (
        <div className='w-full p-5'>
            <h1 className='text-center text-pink-600 font-semibold text-xl'>{description}:</h1>
                 <div ref={sliderRef}>
                <svg
                    className='w-full justify-center'
                    viewBox={`-5 -5 ${width + 10} ${height}`}
                    onClick={e => handleLineClick(e)}
                >
                    <g >
                        {/* outer line */}
                        <line
                            x2={width}
                            className='stroke-pink-300 h-10 stroke-2'
                        />

                        {/* inner line */}
                        <line
                            x1={sliderScale(selectedRange.min)}
                            x2={sliderScale(selectedRange.max)}
                            className='stroke-pink-500 h-10 stroke-2'
                        />

                        {/* ticks */}
                        {sliderScale.ticks().map((t, i) => {
                            const tick = Math.round(t)
                            return (
                                <g key={i} >
                                    <line
                                        x1={sliderScale(tick)}
                                        x2={sliderScale(tick)}
                                        y1={-1}
                                        y2={2} className="stroke-cyan-300 stroke-1 opacity-40" />
                                    {/* {tick % step === 0 && */}
                                        <text
                                            x={sliderScale(tick) - 1}
                                            y={3}
                                            fontSize='2'
                                            className='fill-pink-500 opacity-70'
                                        >
                                            {fieldName === 'grade' ? formatGrade(tick, selectedScale) : tick}
                                        </text>
                                </g>
                            )
                        })}

                        {/* thumbs */}
                        {fields.map((x, i) => {
                            const pos = sliderScale(selectedRange[x])
                            return (
                                <g
                                    className='cursor-grab'
                                    x={pos}
                                    key={i}
                                >
                                    <circle
                                        className='fill-pink-700 stroke-2 opacity-90 '
                                        r='4.5'
                                        cx={pos}
                                    >
                                        <title>{x}: {selectedRange[x]}</title>
                                    </circle>

                                    <text
                                        fontSize={selectedScale === 'yds' && fieldName === 'grade' ? "2" : '3'}
                                        dx={pos - 2.5}
                                        y={1}
                                        className='fill-cyan-200 opacity-90'
                                    >
                                        {fieldName === 'grade' ? formatGrade(selectedRange[x], selectedScale) : selectedRange[x]}
                                    </text>
                                </g>
                            )
                        })}
                    </g>
                </svg>
            </div>
        </div>
    )
}
