import './scatterCSS.css';

export const AxisLeft = ({
  yScale,
  innerWidth,
  tickOffset = 3,
}) =>
  // filter ticks so we only render integers
  yScale.ticks()
    .filter(tick => Number.isInteger(tick))
    .map(tickValue => (
      // render a group with each tick
      <g
        transform={`translate(0,${yScale(tickValue)})`}
        key={tickValue}
      >
        {/* gridlines */}
        <line x2={innerWidth} className="stroke-cyan-500 opacity-40" />
        {/* ticklabels */}
        <text
        className='fill-cyan-600 cursor-default text-end'
          key={tickValue}
          x={-20}
          dy=".32em"
        >
          {tickValue}
        </text>
      </g>
    ));

