export function BodyText() {
    return (
        <div className="w-full justify-center items-center flex-col flex gap-5 flex-wrap text-md my-20 text-teal-800 p-3">
            <h4 className="text-2xl text-center font-semibold">Usage.</h4>
            <ul className="py-10 max-w-md">
                <li>This is meant to be some fun for nerds, the basic premise is that you will be able to see relationships within the data once the dataset is large enough.</li>
                <li>This relies to a large extent on the accurate input of data by knowledgeable users.</li>
                <li>If you want to see the dataset from a subset of users or by other options such as geographic this is possible by using the filtering app.</li>
                <li>Anyone interested in adding their own data can make their own account and get started using the add data form or editing their previously added data using the edit data form.</li>
                <li>You can usually get more detail about something by hovering the mouse over it</li>
                <li>Clicking a point on the chart or the legend highlights the other routes of that grade and attempts to draw a predicted grade line.</li>
                <li>By clicking you can see the details of the route in the box below the chart, click the route name button to see the user logs.</li>
                <li>Hovering over the scale on the X axis shows more details or you can read the full resistance grading scale <a href="/resistances.json">here</a></li>
                <li>When adding data the routes and crags we have in the database will come up as you type in the fields</li>
                <li>You can filter routes for example if you want to view by area you can use the map/country/area filter, or you could view our verified/pro/individual users datasets</li>
                <li>Don't like the grading scale? you can change the scale to another international scale</li>
            </ul>
        </div>
    )
}