import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { feature, mesh } from 'topojson';

const { REACT_APP_WORKER_URL,
  REACT_APP_MONGO_API_KEY,
} = process.env;

const apiHeaders = new Headers();
if (REACT_APP_MONGO_API_KEY) { apiHeaders.set("authorization", REACT_APP_MONGO_API_KEY); }
apiHeaders.set("Content-Type", "application/json;charset=UTF-8");


export const useTopology = () => {

  const worldUrl = 'https://unpkg.com/world-atlas@2.0.2/countries-50m.json';

  const [data, setData] = useState(null);
  useEffect(() => {
    console.log('using topology');
    // let tmp = JSON.parse(localStorage.getItem('world-atlas'));
    // if (tmp) {
    //   console.log(tmp)
    //   setData(tmp)
    // } else {
    fetch(worldUrl).then(response => {
      if (response.ok) {
        console.log(response)
        return response.json()
      }
    })
      .then(topology => {
        console.log(topology)
        const { countries, land } = topology.objects;
        return ({
          countries: feature(topology, countries),
          land: feature(topology, land),
          interiors: mesh(topology, countries, (a, b) => a !== b)
        });

      })
      .then(data => {
        setData(data);
        // localStorage.setItem('world-atlas', JSON.stringify(data));
      })
    // };
  }, []);

  return data;
};
// export const useJson = (dataUrl) => useQuery(dataUrl, async () => {
//   const response = await fetch(dataUrl);
//   return response.json();
// });
// export const useJson = (dataUrl) => {
//   const [data, setData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   useEffect(() => {
//     console.log('getting', dataUrl);
//     let tmp = JSON.parse(localStorage.getItem(dataUrl));
//     if (tmp) {
//         console.log('use json tmp',tmp)
//         setData(tmp)
//     } else {
//       fetch(dataUrl).then(response => {
//         if (response.ok) {
//           console.log(response);
//           return response.json();
//         }
//       }).then(data => {
//         console.log(data);
//         setData(data);
//         localStorage.setItem(dataUrl, JSON.stringify(data))
//         setLoading(false);
//       })
//     };
//   }, [loading])
//   console.log(data)
//   if (!data) setLoading(true)
//   return data;
// }

export const useJson = (dataUrl) =>
  useQuery(dataUrl, async () => {
    const response = await fetch(dataUrl);
    return response.json();
  })
// .then(response => {
//   if (response.ok) {
//     return response.json()
//   }
// }).then(data => {
//   if (data) {
//     console.log(data);
//     return data;
//   }
// });
// });




export const useCSV = dataUrl => useQuery(dataUrl, async () => {
  console.log(dataUrl)
  const response = await fetch(dataUrl,
    {
      method: "GET",
      mode: "cors",
      headers: {
        'content-type': 'application/csv;charset=UTF-8',
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        'Access-Control-Max-Age': '86400',
      },
    });
  console.log(response.text())
  return response.text();
});

// now im storing the data in session storage to prevent excess api calls
// better provide a button to manually refresh data

export const useRetrieveFromDatabase = (path, setter) => {

  let fullPath = `${REACT_APP_WORKER_URL}${path}`
  useEffect(() => {
    let tmp = JSON.parse(sessionStorage.getItem(path));
    if (tmp) {
      console.log(tmp)
      setter(tmp)
    } else {
      fetch(fullPath,
        {
          method: "GET",
          // mode: "cors",
          headers: apiHeaders,
        }).then(response => {
          if (response.ok) {
            console.log("i've just made a call to the api, here is the response:", response)
            return response.json()
          } else return 0
        }).then(data => {
          if (data) {
            setter(data)
            sessionStorage.setItem(path, JSON.stringify(data))
          }
        })
    }
  }, [path, setter])
}


export async function registerUser({ newUser }
) {
  return await fetch(`${REACT_APP_WORKER_URL}/register`,
    {
      method: 'POST',
      mode: 'cors',
      headers: apiHeaders,
      body: JSON.stringify({
        newUser: { ...newUser }

      }),
    }).then(response => {
      if (response.ok) {
        return response.json()
      }
    }).then(data => {
      if (data) {
        console.log('login', data);
        return data;
      }
    })
}


export async function loginUser(usernameAndPassword) {
  return await fetch(`${REACT_APP_WORKER_URL}/login`,
    {
      method: 'POST',
      mode: 'cors',
      headers: apiHeaders,
      body: JSON.stringify({
        user: { ...usernameAndPassword }

      }),
    }).then(response => {
      if (response.ok) {
        return response.json()
      }
    }).then(data => {
      if (data) {
        console.log('login', data);
        return data;
      }
    })
}



export async function handleAddDataSubmit({
  routeInput,
  userID,
  cragInput,
  setData,
  token,
  mode = 'add',
}) {
  console.log('submitting data', token)
  apiHeaders.set("userApiKey", token,)
  // minimal error checking
  if (!userID) { return alert("Whoops, you have to be signed in to do that") }
  if (!window.confirm
    ('Do you want to submit route: '
      + routeInput.route_name)
  ) { return }



  let logUrlPath = "";
  let toBeInserted = {};


  if (mode === 'add') {
    toBeInserted = {
      route_name: routeInput.route_name,
      grade: +routeInput.average_grade,
      crux: +routeInput.average_crux,
      resistance: +routeInput.average_res,
      comment: routeInput.comment,
      date: Date.now(),
      user_id: userID,
    };
    console.log('no path')
    // route exists
    // routeInput should have route id
    if (routeInput._id) {
      logUrlPath = '/log/existing';
      toBeInserted.route_id = routeInput._id;
    }
    else {
      logUrlPath = '/log/new';
      // route not in db, crag exists
    }

    // cragID will be included in state
    if (cragInput._id) {
      toBeInserted.crag_id = cragInput._id;
    } else {
      // route not in db, crag not in db
      // crag name, country, long, lat included
      toBeInserted.crag_name = cragInput.crag_name;
      toBeInserted.country = cragInput.country;
      toBeInserted.long = +cragInput.longitude;
      toBeInserted.lat = +cragInput.latitude;
    }
  } else {
    logUrlPath = '/log/edit';
    toBeInserted = {
      _id: routeInput._id,
      route_id: routeInput.route_id,
      grade_opinion: routeInput.grade_opinion,
      crux_opinion: routeInput.crux_opinion,
      res_opinion: routeInput.res_opinion,
      comment: routeInput.comment,
      date: Date.now(),

    }
  }


  console.log(logUrlPath, toBeInserted)

  return await fetch(`${REACT_APP_WORKER_URL}${logUrlPath}`,
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        routeLog: { ...toBeInserted },
      }),
      headers: apiHeaders,
    })
    // after set form to default, retrieve all routes
    .then(response => {
      if (response.ok) {
        return response.json()
      }
    }).then(data => {
      if (data) {
        console.log(data)
        setData(currentData => {
          const newData =
          {
            routes: [
              ...currentData.routes.filter(d => d._id !== data.route._id),
              data.route,
            ],
            route_logs: currentData.route_logs,
            // [
              // ...currentData.route_logs.filter(d => d.route_id !== data.route._id),
              // data.routeLog,
            // ],
            users: currentData.users,
            crags: currentData.crags,
          }
          console.log(newData);
          sessionStorage.setItem('/dump', JSON.stringify(newData));
          return newData;
        })
        return data;
      }
    })

}

// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
// export function useToken() {
//   function getToken() {
//     const tokenString = localStorage.getItem('token');
//     const userToken = JSON.parse(tokenString);
//     console.log(userToken)
//     return userToken ? userToken : null
//   };
//   const [token, setToken] = useState(getToken());
//   function saveToken(userToken) {
//     localStorage.setItem('token', JSON.stringify(userToken));
//     setToken(userToken);
//   }
//   return {
//     setToken: saveToken,
//     token
//   }
// }

export function useUser() {
  function getUser() {
    const userString = localStorage.getItem('storedUser');
    let userObj = null;
    if (userString) { userObj = JSON.parse(userString); }
    return userObj //? userObj : null
  };
  const [user, setUser] = useState(getUser());
  function saveUser(userObj) {
    localStorage.setItem('storedUser', JSON.stringify(userObj));
    setUser(userObj);
  }
  function logout() {
    localStorage.removeItem('storedUser');
    setUser(null)
  }
  return {
    setUser: saveUser,
    logoutStorage: logout,
    user,
  }
}