import { useState } from 'react';

export const Search = ({
    suggestions,
    fieldName,
    value,
    handleChange,
    placeholder = 'type here...',
    autoFocus = false,
}) => {
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState(value);

    const onChange = e => {
        // suggestions.map(suggestion => {
        //     console.log(suggestion[fieldName].toLowerCase());
        //     console.log(e.currentTarget.value.toLowerCase());
        // })
        console.log(e)
        setUserInput(e.currentTarget.value);
        setFilteredSuggestions(suggestions.filter(
            suggestion =>
                suggestion[fieldName].toLowerCase().indexOf(e.currentTarget.value.toLowerCase()) > -1,
        ))
        setShowSuggestions(true);
        setActiveSuggestion(0);
        handleChange({ name: fieldName, value: e.currentTarget.value, matchFound: 0 })
    };

    const onClick = e => {
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
        setUserInput(e.currentTarget.innerText);
        handleChange({ name: fieldName, value: e.currentTarget.innerText, matchFound: e.target.id });
    }

    const onKeyDown = e => {
        // enter key
        if (e.keyCode === 13) {
            setActiveSuggestion(0);
            setShowSuggestions(false);
            setUserInput(filteredSuggestions[activeSuggestion].route_name);
            handleChange({
                name: fieldName,
                value: filteredSuggestions[activeSuggestion].route_name,
                matchFound: filteredSuggestions[activeSuggestion]._id
            });
            // up arrow
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) { return; }
            setActiveSuggestion(activeSuggestion - 1);
            // down arrow
        } else if (e.keyCode === 40) {

            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1)
        }
    }

    const SuggestionsList = () => {
        if (filteredSuggestions.length) {
            return <ul className='list-none overflow-y-auto max-h-40 w-4/5 p-2 capitalize'>
                {filteredSuggestions.map((suggestion, index) => {
                    return (
                        <li
                            className={index === activeSuggestion ? 'px-5 bg-pink-200 rounded-lg text-cyan-600 font-semibold cursor-pointer' : 'bg-cyan-300 text-pink-600'}
                            key={index}
                            onClick={onClick}
                            id={suggestion._id}
                        >
                            {suggestion[fieldName]}
                        </li>
                    );
                })}
            </ul>
        } else {
            return <div className='p-3 w-4/5 text-pink-400 text-justify'>
                <em>No matching routes, check spelling or continue to add new.</em>
            </div>
        }
    }

    return (
        <>
            <input
                className=' px-5 w-4/5 rounded-md text-pink-800'
                type='text'
                name={fieldName}
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={userInput}
                autoFocus={autoFocus}
                placeholder={placeholder}
                required={true}
                autoComplete={false}
            />
            {(userInput && showSuggestions) && <SuggestionsList />}
        </>
    )
};
