export const MultiFilter = ({
    fieldName,
    setSelected,
    selected,
    data,
}) => {
    const handleChange = e => {
        if (!e.target.value.length) {
            setSelected([])
        } else {
            setSelected((currentFilters) => {
                return [...new Set([
                    ...currentFilters,
                    e.target.value
                ])]
            })
        }
    }

    return (
        <div className='p-2 text-center text-pink-600 items-center justify-center min-w-xs max-w-sm bg-inherit w-full'>
            {data ?
                <div className="p-2 w-full items-center justify-center min-w-xs max-w-sm bg-inherit">
                    <h5 className="font-semibold">Filter By {fieldName}</h5>
                    <select
                        className="rounded-xl w-full bg-inherit p-3 shadow-lg"
                        // this doesnt have the desired effect atm on mobile
                        // multiple={true}
                        onChange={e => handleChange(e)}
                        defaultValue={null}
                    >
                        <option
                            value={[]}
                            key='all'
                        >All</option>

                        {data.map((d) => (
                            <option
                                value={d._id}
                                key={d._id}
                            >
                                {d.first_name} {d.last_name}
                            </option>
                        ))}
                    </select>
                    <DisplayBox
                        fieldName={fieldName}
                        selected={selected}
                        setSelected={setSelected}
                        data={data}
                    />
                </div>
                : <pre>Loading...</pre>
            }
        </div>
    )

};



export const DisplayBox = ({
    fieldName,
    selected,
    setSelected,
    data,
}) => {
    let named = selected.map(d => {
        for (let i = 0; i < data.length; i++) {
            if (d === data[i]._id || +d === data[i]._id) {
                return data[i]
            }
        } return false;
    })
    const handleChange = ({ id }) => {
        setSelected(selected.filter(item => {
            // console.log(item, id.toString())
            return item !== id.toString()//this is dumb, eventually make all ids strings
        }
        ))
    }
    if (selected.length)
        return (
            <div className='flex flex-col  items-center w-full text-pink-600 bg-teal-400 border-teal-600 rounded-full min-w-xs max-w-sm'
            // `selected-${fieldName}-display selected-display`}
            >
                <h5 className="p-2 font-semibold">Selected {fieldName}:</h5>
                {named.map(d =>
                    <div className='w-full flex-row flex items-center gap-2 justify-end'
                        // {`selected-${fieldName}-option selected-option`}
                        key={d._id}
                    >
                        <label
                            className='w-full text-right capitalize'
                            htmlFor={d._id}
                        >
                            {`${d.first_name} ${d.last_name}`}
                        </label>
                        <input
                            className='m-2 w-auto'
                            type='checkbox'
                            name={d._id}
                            checked={true}
                            onChange={() => handleChange({ id: d._id })}
                        />
                    </div>
                )}
            </div>
        )
}
