import { AxisBottom } from "./AxisBottom";
import { AxisLeft } from "./AxisLeft";

export const Axes = ({
    xScale,
    innerHeight,
    innerWidth,
    axisTickOffset,
    xAxisLabelOffset,
    xAxisLabelDef,
    xAxisLabel,
    xAxisTickNumber, 
    yScale,
    yAxisLabelOffset,
    yAxisLabelDef,
    yAxisLabel,
}) => {
    return (
        <g>
            <AxisBottom
                xScale={xScale}
                innerHeight={innerHeight}
                innerWidth={innerWidth}
                tickOffset={axisTickOffset}
                tickNumber={xAxisTickNumber}
            />
            {/* <text
                className="fill-pink-600 cursor-default"
                x={innerWidth / 2}
                y={innerHeight + xAxisLabelOffset}
                textAnchor="middle"
            >
                <title>{xAxisLabelDef}</title>
                {xAxisLabel}
            </text> */}

            {/* taking care of the y Axis */}
            {/* y axis line */}
            <line
                className='stroke-cyan-800 opacity-60'
                y1={0}
                y2={innerHeight}
            />
            <AxisLeft
                yScale={yScale}
                innerWidth={innerWidth}
                innerHeight={innerHeight}
                tickOffset={axisTickOffset}
            />
            <text
                className="fill-teal-600 cursor-default"
                textAnchor="middle"
                transform={`translate(${-yAxisLabelOffset},${innerHeight /
                    2}) rotate(-90)`}
            >
                <title>{yAxisLabelDef}</title>
                {yAxisLabel}
            </text>
            {/* drawing the x axis line after y ticks rendered*/}
            <line
                className='stroke-pink-800 opacity-60'
                x1={0}
                x2={innerWidth}
                y1={innerHeight}
                y2={innerHeight}
            />
        </g>
    )
}