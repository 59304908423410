import { useContext, useState } from "react"
import { gradeScaleOptions } from "../GradeScaleSelection"
import { registerUser } from 'src/hooks'
import { UserContext } from 'src/Context';
import { FaSpinner } from 'react-icons/fa';


export const RegisterApp = ({
    setRegistrationToggle
}: any) => {

    const [user, setUser] = useContext(UserContext);
    const [username, setUsername] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordCheck, setPasswordCheck] = useState("")
    const [prefScale, setPrefScale] = useState('ewbank')
    const [askPro, setAskPro] = useState(false)
    const [askVerify, setAskVerify] = useState(false)
    const [loading, setLoading] = useState(false)

    // const { setToken } = useToken();


    const inputFields = [
        {
            fieldName: username,
            type: 'text',
            onchange: setUsername,
            label: 'Username',
            required: true,
            autocapitalize: 'off',

        },
        {
            fieldName: firstName,
            type: 'text',
            onchange: setFirstName,
            label: 'First Name',
            required: true,
            autocapitalize: 'words',

        },
        {
            fieldName: lastName,
            type: 'text',
            onchange: setLastName,
            label: 'Last Name',
            required: true,
            autocapitalize: 'words',

        },
        {
            fieldName: email,
            type: 'text',
            onchange: setEmail,
            label: 'Email Address',
            required: false,
            autocapitalize: 'off',

        },
        {
            fieldName: password,
            type: 'password',
            onchange: setPassword,
            label: 'Password',
            required: true,
            autocapitalize: 'off',

        },
        {
            fieldName: passwordCheck,
            type: 'password',
            onchange: setPasswordCheck,
            label: 'Re-type Password',
            required: true,
            autocapitalize: 'off',

        },
    ]
    const checkInputs = [
        {
            fieldName: 'askPro',
            value: askPro,
            type: 'checkbox',
            onchange: () => { askPro ? setAskPro(false) : setAskPro(true) },
            label: 'Are you a professional climber?',
            required: true,
        },

        {
            fieldName: 'askVerify',
            value: askVerify,
            type: 'checkbox',
            onchange: () => { askVerify ? setAskVerify(false) : setAskVerify(true) },
            label: 'Do you want your data to appear in the vefified climber dataset?',
            required: true,
        },
    ]

    const register = async () => {
        if (password === passwordCheck) {
            let newUser = {
                username: username,
                first_name: firstName,
                last_name: lastName,
                email_address: email,
                password: password,
                pref_scale: prefScale,
                ask_pro: askPro,
                ask_verify: askVerify,
            }
            setLoading(true);
            const user = await registerUser({ newUser: { ...newUser } });
            if (user.user) {

                setUser(user)
                // setToken(user.apiKey)
                setRegistrationToggle(false);
            } else alert('registration failed')
            setLoading(false)
        } else { alert('passwords dont match') }
    }

    if (loading) return <FaSpinner className='animate-spin w-full m-10 text-2xl fill-amber-500' />

    return (

        <form
            className="bg-opacity-50 bg-pink-300 rounded-3xl p-5 w-full flex flex-col justify-center items-center gap-2 text-pink-800"
            onSubmit={e => e.preventDefault()}>
                <h1>Create an account so you can add in data.</h1>
                <h1>We only need your email address if you wan't your data listed in the pro or verified datasets.</h1>
            {inputFields.map(input =>
                <input
                    key={input.label}

                    className="max-w-xl w-full p-5 rounded-3xl"
                    value={input.fieldName}
                    onChange={e => input.onchange(e.target.value)}
                    type={input.type}
                    placeholder={`${input.label}${input.required ? "*" : ""}`}
                    required={input.required}
                    autoCapitalize={input.autocapitalize}
                />
            )}

            {checkInputs.map(input =>
                <div
                    className='max-w-xl w-full px-5 flex flex-row justify-center items-center h-full'
                    key={input.fieldName}
                >
                    <label
                        htmlFor={input.fieldName}
                        className='w-full'
                    >
                        {input.label}
                    </label>
                    <input
                        className="ml-3 w-1/5 min-w-fit h-12"
                        type='checkbox'
                        checked={input.value}
                        name={input.fieldName}
                        onChange={input.onchange}
                    />
                </div>
            )}
            {/* pref grading scale */}
            <div
                className="max-w-xl w-full p-5 flex flex-row justify-center items-center"
            >
                <label
                    className='w-full'
                    htmlFor="pref-scale"
                >Preferred Grading Scale:</label>
                <select
                    name='pref-scale'
                    className='min-w-fit w-1/5 ml-3 p-5 bg-cyan-200 rounded-t-3xl rounded-b-md'
                    onChange={e => setPrefScale(e.target.value)}
                    value={prefScale}
                >
                    {gradeScaleOptions.map((scale) =>
                        <option
                            className=""
                            value={scale}
                            key={scale}
                        >{scale}</option>)
                    }
                </select>
            </div>
            <button
                className="p-5 bg-cyan-500 rounded-full min-w-fit w-1/5 shadow-2xl"
                type='submit'
                onClick={register}
            >
                Register
            </button>
            <button
                className="p-5 bg-cyan-500 rounded-full min-w-fit w-1/5 shadow-2xl"
                onClick={() => setRegistrationToggle()}
            >
                Register Later
            </button>
        </form >

    )
}

