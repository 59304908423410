export const DataCircle = ({
    onHover,
    x = 0,
    y = 0,
    toolTip,
    value,
    classDef,
}) => {
    const circleProps = {
        className: classDef,
        cx: x,
        cy: y,
        // onMouseEnter: () => { onHover(value) },
        onPointerEnter: () => {
            // console.log("pointer event", value)
            onHover(value)
        },
        onClick: () => {
            console.log(value)
            onHover(value)
        },
        // onMouseLeave: () => { onHover(null) },
    };
    return (
        <circle {...circleProps} >
            <title>{toolTip}</title>
        </circle>
    )
};


export const giveClassDef = (d, hoveredValue, pointValue) => {
    return `grade${pointValue(d)}
    ${hoveredValue ?
            +pointValue(hoveredValue) === pointValue(d) ?
                'selected-' : 'unselected-' : 'noselection-'}grade-point
        ${hoveredValue && d ?
            hoveredValue._id === d._id ?
                'selected-' : 'unselected-' : 'noselection-'}data-point`
}

export const giveLegendClassDef = (hoveredValue, d, pointValue) => {
    // console.log(hoveredValue, d)
    return `grade${d}
    ${hoveredValue ?
            +pointValue(hoveredValue) === d ?
                'selected-' : 'legend-' : 'legend-'}grade-point
                ${hoveredValue && d ?
            +pointValue(hoveredValue) === d ?
                'selected-data-' : 'legend-grade-' : 'legend-grade-'}point`
}
