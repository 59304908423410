import { AddDataComponent } from "./AddDataComponent";

export const CommentEntry = ({
    handleChange,
    value,
    fieldName,

}) => {
    return (
        <AddDataComponent title="Leave a Comment:" >
            <p className="text-justify">You can talk about how many cruxes, how many rests, breakdown of difficulty.</p>
            <textarea
            className="overflow-auto h-28 w-5/6 overflow-y-auto rounded-lg p-2 sm:p-5"
                type='text'
                name={fieldName}
                required={false}
                value={value}
                onChange={e => handleChange(e.target)}//{name: fieldName, value:e.target.valueAsNumber})}
            />
        </AddDataComponent>
    )
};