import { AddDataComponent } from "./AddDataComponent"

export const CountryEntry = ({
    handleChange,
    value,
    fieldName,

}) => {
    return (
        <AddDataComponent title="Country:" >
            <input
                className="px-5 rounded-lg w-4/4"
                type='text'
                name='country'
                required={true}
                value={value}
                onChange={e => handleChange({ name: fieldName, value: e.target.value })}
            />
        </AddDataComponent>
    )
}