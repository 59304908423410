// import { ResistanceTickFormat } from "../FormatResistanceScale";

import { DisplayValue } from "./AddDataComponent";

const resistanceInfo = [
    {
        "range": [0, 10],
        "name": "Boulder Problem",
        "description": "A short route or boulder problem, less than 10 moves. Crux is 10 x boulder grades harder than anything else on the route"
    },
    {
        "range": [11, 20],
        "name": "Long Boulder",
        "description": "Short route less than 15 moves. Crux is 8 x boulder grades harder than any other move"
    },
    {
        "range": [21, 30],
        "name": "Short Route",
        "description": "Less than 20 moves. Crux is 6 x harder than the rest."
    },
    {
        "range": [31, 40],
        "name": "Slightly Sustained",
        "description": "Around 25 moves. Good rests allowing full recovery. Two sections of similar difficulty or crux 4 x harder than rest"
    },
    {
        "range": [41, 50],
        "name": "Pumpy",
        "description": "30 moves. Three or more similarly difficult sections broken by good rests or long section with bad rests"

    },
    {
        "range": [51, 60],
        "name": "Very Pumpy",
        "description": "35 moves. Bad rests between +- 4 boulder grade climbing or ok rests between +- 3 boulder grade sections"

    },
    {
        "range": [61, 70],
        "name": "Hard Very Pumpy",
        "description": "45 moves. No handsfree rests. 80% of route is similar in difficulty and within 3 boulder grades of the crux"

    },
    {
        "range": [71, 80],
        "name": "HVS Pumpy",
        "description": "50 moves. No decent rests. Similar difficulty of 90% of the route, crux + 2 boulder grades from rest"

    },
    {
        "range": [81, 90],
        "name": "Extremely Pumpy",
        "description": "A long route, 55 moves. No rests. All of the moves are similar in difficulty +- 1 x boulder grade"
    },
    {
        "range": [91, 98],
        "name": "Perfect Pump",
        "description": "60 moves or more. Every move is the same difficulty. No hint of a rest."
    },
    {
        "range": [99, 100],
        "name": "Impossibly Pumpy",
        "description": "Impossibly pumpy, This 100+ moves at the same difficulty."
    },

]

// let resistanceInfo = ResistanceTickFormat();




export const ResistanceEntry = ({
    handleChange,
    value,
    fieldName,

}) => {
    const min = 0;
    const max = 100;
    const step = 1;
    const resistanceFormat = (value = 0) => {
        if (value) {
            for (let i = 0; i < resistanceInfo.length; i++) {
                if ((value >= resistanceInfo[i].range[0]) && (value <= resistanceInfo[i].range[1])) {
                    return { name: resistanceInfo[i].name, description: resistanceInfo[i].description };
                }
            }
        } else { return "" }
    }
    const sliderChange = (target) => {
        if (target.value >= min && target.value <= max) {
            handleChange(target)
        }
    }

    return (
        <div className="w-full justify-center items-center flex flex-col gap-3 py-3 px-2 bg-gradient-to-tr from-transparent to-pink-200 rounded-xl shadow-xl">
            <h5 className="text-center font-semibold p-3">Resistance</h5>
            <p>This is how pumpy the route is, on a scale of 0-100!</p>
            <p>For help on choosing hover over the scales on the slider.</p>

            <div className="max-w-lg min-w-xs w-full">
                <input
                    className="w-full"
                    type='range'
                    name={fieldName}
                    required={true}
                    value={value}
                    onChange={e => sliderChange(e.target)}
                    min={min}
                    max={max}
                    step={step}
                />
                <datalist className='resistance-markers w-full flex flex-col justify-between' id='resistance-markers'>
                    {resistanceInfo.map(({ range, name, description }) => (
                        <option
                            value={range[0]}
                            key={name}
                            label={name}
                            title={description}
                        >
                            {name}
                        </option>
                    ))}
                </datalist>
            </div>
            <DisplayValue fieldName={fieldName} value={value} handleChange={sliderChange}>
                {value && <h4 className="w-full rounded-2xl bg-pink-200 font-semibold p-3 text-xl text-center">{value}</h4>}

            </DisplayValue>
            <div className="w-fit rounded-2xl bg-pink-200 font-semibold p-3 text-xl">
                <h4 className="text-center">
                    {value}: {resistanceFormat(value).name}
                </h4>
                <p className="text-sm font-light text-justify">{resistanceFormat(value).description}</p>
            </div>
        </div>
    )
}
