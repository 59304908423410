import { AddDataComponent } from "./AddDataComponent";

export const CoordEntry = ({
    handleChange,
    value,
    fieldName,

}) => {
    return (
        <AddDataComponent title={`${fieldName}:`}>
            <input
            className="rounded-lg w-4/5 px-5"
                type='number'
                name={fieldName}
                required={false}
                value={value}
                onChange={e => handleChange({ name: fieldName, value: e.target.value })}
                step={0.001}
            />
        </AddDataComponent>
    )
};