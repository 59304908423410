export const checkData = ({
    dataSet,
    dataSetField,
    dataPointField,
}) => {
    for (let i = 0; i < dataSet.length; i++) {
        if (dataPointField === dataSet[i][dataSetField]) {
            return dataPointField
        }
    }
    return false;
}

// convert user ids to strings to merge this with country filter
// user filter
export const userFilter = ({
    selectedUsers,
    routeLogData,
    filteredData,
}) => {
    if (selectedUsers.length) {
        let routesByUsers = routeLogData.filter(log => {
            for (let i = 0; i < selectedUsers.length; i++) {
                if (log.user_id === +selectedUsers[i] ||
                    log.user_id === selectedUsers[i]) {
                    return true
                }
            }
            return false;
        });
        return filteredData.filter(d => {
            for (let i = 0; i < routesByUsers.length; i++) {
                if (routesByUsers[i]['route_id'] === d._id) {
                    return d;
                }
            }
            return false;

            // (checkData({ dataSet: routesByUsers, dataSetField: 'route_id', dataPointField: d._id }))
        });
    };
    return filteredData;
}

export const countryFilter = ({
    selected,
    dataSet,
    filterField,
    filteredData,
}) => {
    if (selected.length) {
        let cragsInCountries = dataSet.filter(d => {
            for (let i = 0; i < selected.length; i++) {
                if (d[filterField] === selected[i]) {
                    return true;
                }
            }
            return false;
        });
        filteredData = (filteredData.filter(d => {
            for (let i = 0; i < cragsInCountries.length; i++) {
                if (cragsInCountries[i]["_id"] === d.crag_id) {
                    return d;
                }
            }
            return false;

        }));
    };
    return filteredData;
}
export const applyFilter = ({
    selected,
    filteredData,
    filterField,
}) => {
    if (selected.length) {
        filteredData = filteredData.filter(d => {
            for (let i = 0; i < selected.length; i++) {
                // give selected areas crag_id
                if (d[filterField] === selected[i]) {
                    return true
                }
            } return false
        });
    }
    return filteredData;
}


export const binaryFilter = ({
    filter,
    primaryDataSet,
    secondaryDataSet,
    filterField,
    filteredData
}) => {
    if (filter) {
        let selection = primaryDataSet.filter(d => d[filterField])
        let routesBySelection = secondaryDataSet.filter(d => {
            for (let i = 0; i < selection.length; i++) {
                if (d.user_id === selection[i]._id) {
                    return true//routeLog.route_id
                }
            }
            return false;

        })
        return filteredData.filter(d => {
            for (let i = 0; i < routesBySelection.length; i++) {
                if (routesBySelection[i].route_id === d._id) {
                    return true
                }
            }
            return false;
        })
    }
    return filteredData;
}

export const ratingFilter = ({
    check,
    filteredData,
    filterField,
    selectedRange,
}) => {
    if (check) {
        return filteredData.filter(d =>
            d[filterField] >= selectedRange.min &&
            d[filterField] <= selectedRange.max)
    }
    return filteredData;
}