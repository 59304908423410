export const AddDataComponent = ({
    title,
    children,
    value = null,
}: any) => {


    return (
        <div className="w-full max-w-sm min-w-full justify-center items-center flex flex-col gap-3 p-2 bg-gradient-to-tr from-transparent to-pink-200 rounded-xl shadow-xl">
            <h5 className="text-center font-semibold p-3"> {title} </h5>
            {children}
            {value && <h4 className="w-fit rounded-2xl bg-pink-200 font-semibold p-3 text-xl">{value}</h4>}
        </div>
    )
}

export const DisplayValue = ({
    fieldName,
    value,
    handleChange,
    step = 1,
    children,
}: any) => {

    return (
        <div className='flex flex-row w-full gap-1 items-center justify-center'>
            <button
                className='p-1 bg-pink-400 text-teal-700 rounded-lg shadow-lg font-bold text-xl w-16 text-center'
                onClick={(e) => {
                    e.preventDefault();
                    handleChange({ name: fieldName, value: +Math.round(+(+value - +step) * 10) / 10 })
                }}>-</button>
            {children}
            <button
                className='p-1 bg-pink-400 text-teal-700 rounded-lg shadow-lg font-bold text-xl w-16 text-center'
                onClick={(e) => {
                    e.preventDefault();
                    handleChange({ name: fieldName, value: +Math.round(+(+value + +step) * 10) / 10 })
                }}>+</button>
        </div>
    )
}