import { Search } from "./AutoComplete";
import { DataContext } from "src/Context";
import { useContext } from 'react';
import { AddDataComponent } from "./AddDataComponent";

export const AreaEntry = ({
    handleChange,
    value,
    cragData,

}) => {
    const [data, setData] = useContext(DataContext);

    return (
        <AddDataComponent title="Search Areas:">

            <Search
                suggestions={data.crags}
                fieldName={'crag_name'}
                value={value}
                handleChange={handleChange}
                placeholder={"Type to see what we've got..."}
                autoFocus={false}
            />
        </AddDataComponent>
    )
}