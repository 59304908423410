import { useContext, useMemo, useState } from 'react';
import { scaleLinear, min, max } from 'd3';
import { Axes } from './Axes';
import { GradeDataPoints } from './GradeDataPointsV2';
import { RegressionForecast } from './RegressionForecast';
import { HoveredContext } from 'src/Context';


const width = 500;
const height = 400;
const margin = { top: 10, right: 7, bottom: 110, left: 40 };
const xAxisLabelOffset = 110;
const yAxisLabelOffset = 25;
const axisTickOffset = 3;
const xBuffer = 5;
const yBuffer = 1;

const innerHeight = height - margin.top - margin.bottom;
const innerWidth = width - margin.left - margin.right;

// const xValue = d => d.average_res;
const xAxisLabel = 'Resistance';
const xAxisLabelDef = 'This is how pumped you get when you climb the route!';

// const yValue = d => d.average_crux;
const yAxisLabel = 'Crux Grade (V)';
const yAxisLabelDef = 'This is the individual difficulty of the hardest section expressed in V Grades';


export const Chart = ({
    xValue = d => d.average_res,
    yValue = d => d.average_crux,
    pointValue = d => d.average_grade,
    pointName = d => d.route_name,
    filteredData,
    onHover,
    gradeData,

}) => {
    const [hoveredValue, setHoveredValue] = useContext(HoveredContext);
    // const [gradeData, setGradeData] = useState(null);


    // const onHover = (value) => {
    //     console.log(value)
    //     setGradeData(filteredData.filter(d =>
    //         pointValue(d) === pointValue(value)));
    //     setHoveredValue(value)
    // }

    const xScale = useMemo(() =>
        scaleLinear()
            // the x scale goes from min 0 or min of filtered data minus a buffer if more than 0 to the inverse for max
            .domain([max([(min(filteredData, xValue) - xBuffer), 0]), min([(max(filteredData, xValue) + xBuffer), 100])])// extent(filteredRoutese))
            .range([0, innerWidth])
            .nice()
        , [filteredData, xValue]);

    const yScale = useMemo(() =>
        scaleLinear()
            .domain([min(filteredData, yValue) - yBuffer, max(filteredData, yValue) + yBuffer])//extent(filteredRoutese))
            .range([innerHeight, 0])
            .nice()
        , [filteredData, yValue]);

    const xAxisTickNumber = useMemo(() =>
        ((max(filteredData, xValue) + 20) - min(filteredData, xValue)) / 10, [filteredData, xValue])

    if (!filteredData.length) {
        return <pre>Loading data...</pre>
    }

    if (!filteredData) {
        return <pre>Error loading data</pre>
    }

    return (
        <svg
            className='ml-2 h-auto  sm:basis-9/12 md:max-w-8/12 lg:max-w-6/12'
            viewBox={`0 0 ${width} ${height}`}
        // onClick={() => setHoveredValue(null)}
        >
            <g transform={`translate(${margin.left},${margin.top})`}>
                {/* taking care of the Axes */}
                <Axes
                    xScale={xScale}
                    innerHeight={innerHeight}
                    innerWidth={innerWidth}
                    axisTickOffset={axisTickOffset}
                    xAxisLabelOffset={xAxisLabelOffset}
                    xAxisLabelDef={xAxisLabelDef}
                    xAxisLabel={xAxisLabel}
                    xAxisTickNumber={xAxisTickNumber}
                    yScale={yScale}
                    yAxisLabelOffset={yAxisLabelOffset}
                    yAxisLabelDef={yAxisLabelDef}
                    yAxisLabel={yAxisLabel}
                />
                {/* forecast line */}
                <RegressionForecast
                    xScale={xScale}
                    yScale={yScale}
                    data={gradeData}
                />
                {/* adding data points */}
                <GradeDataPoints
                    hoveredValue={hoveredValue}
                    data={filteredData}
                    xScale={xScale}
                    xValue={xValue}
                    yScale={yScale}
                    yValue={yValue}
                    pointValue={pointValue}
                    pointName={pointName}
                    onHover={onHover}
                />
            </g>
        </svg>

    )

}