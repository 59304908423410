import { DataCircle, giveClassDef } from './DataCircle'
import { formatGrade } from '../GradeScaleSelection'
import { useContext } from 'react';
import { GradeContext } from 'src/Context';

export const GradeDataPoints = ({
    data,
    xScale,
    xValue,
    yScale,
    yValue,
    pointValue,
    pointName,
    onHover,
    hoveredValue,
}) => {
    const [selectedScale, _] = useContext(GradeContext)
    return <g>
        {data.map((d) => {
            return <DataCircle
                classDef={giveClassDef(d, hoveredValue, pointValue)}
                value={d}
                key={d._id}
                onHover={onHover}
                x={xScale(xValue(d))}//.average_res)}
                y={yScale(yValue(d))}//d.average_crux)}
                toolTip={`${pointName(d)}, ${formatGrade(pointValue(d), selectedScale)}`}
            />
        })}
    </g>
}

