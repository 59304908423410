import { useState, useReducer, useContext } from "react"
import { RouteNameEntry } from "./RouteNameEntry";
import { GradeEntry } from "./GradeEntry";
import { CruxEntry } from "./CruxEntry";
import { ResistanceEntry } from "./ResistanceEntry";
import { CommentEntry } from "./CommentEntry";
import { CragEntry } from "./CragEntry";
import { handleAddDataSubmit } from "src/hooks";
import { DataContext, UserContext } from "src/Context";
import { CollapsibleComponent } from "../CollapseToggle";

const defaultRouteInput = {
    _id: 0,
    route_name: '',
    average_grade: 0,
    average_crux: 0,
    average_res: 0,
    comment: '',
    date: 'now',
}

const defaultCragInput = {
    _id: 0,
    crag_name: "",
    country: "",
    latitude: 0,
    longitude: 0,
}

export const NewDataApp = () => {

    const [data, setData] = useContext(DataContext);
    const [user, setUser] = useContext(UserContext);

    const [toggleEditCrag, setToggleEditCrag] = useReducer(state => !state, false);
    const [routeInput, setRouteInput] = useState(defaultRouteInput);
    const [cragInput, setCragInput] = useState(defaultCragInput);



    const handleSubmit = (event) => {
        event.preventDefault();
        handleAddDataSubmit({
            routeInput: routeInput,
            userID: user.user._id,
            cragInput: cragInput,
            setData: setData,
            token: user.apiKey,
        });
        setCragInput(defaultCragInput);
        setRouteInput(defaultRouteInput);
    }

    const getCragData = (route) => {
        console.log('matched route passed to get crag: ', { route })
        for (let i = 0; i < data.crags.length; i++) {
            if (data.crags[i]._id === route.crag_id) {
                // console.log('crag: ', { data.crags[i] });
                setCragInput(data.crags[i]);
            }
        }
    }

    const findMatch = (match, dataSet) => {
        for (let i = 0; i < dataSet.length; i++) {
            if (dataSet[i]._id === match) {
                return (dataSet[i])
            }
        }
    }

    const handleChange = (target) => {
        setRouteInput(
            (currentRouteInput) => {
                return {
                    ...currentRouteInput,
                    [target.name]: target.value,
                }
            }
        );
        if (target.matchFound) {
            let matchedRoute = findMatch(target.matchFound, data.routes)
            if (matchedRoute) {
                setRouteInput(matchedRoute)
                getCragData(matchedRoute)
                setToggleEditCrag(false)
            } else { console.log("unable to match id") }
        }
    }


    return (
        <CollapsibleComponent title='Add New Data'>
            <div className="flex flex-wrap flex-col justify-center items-center w-full text-pink-900">
                {data.routes &&
                    <form
                        className="flex flex-col w-full justify-center items-center py-5 max-w-sm min-w-xs gap-3"
                        onSubmit={e => e.preventDefault()}
                    >
                        <p>Give your best independent opinion of the route, only if you have done it!</p>
                        <div className=" mt-10 flex flex-col flex-wrap justify-center items-center w-full p-2">
                            <RouteNameEntry
                                handleChange={handleChange}
                                value={routeInput.route_name}
                            />
                        </div>

                        {routeInput.route_name &&
                            <>
                                <>
                                    <GradeEntry
                                        handleChange={handleChange}
                                        value={routeInput.average_grade}
                                        fieldName={"average_grade"}
                                    />

                                    <CruxEntry
                                        handleChange={handleChange}
                                        value={routeInput.average_crux}
                                        fieldName={"average_crux"}
                                    />

                                    <ResistanceEntry
                                        handleChange={handleChange}
                                        value={routeInput.average_res}
                                        fieldName={"average_res"}
                                    />

                                    <CommentEntry
                                        handleChange={handleChange}
                                        value={routeInput.comment}
                                        fieldName={"comment"}
                                    />
                                </>

                                <CragEntry
                                    cragInput={cragInput}
                                    setCragInput={setCragInput}
                                    toggleEditCrag={toggleEditCrag}
                                    setToggleEditCrag={setToggleEditCrag}
                                    cragData={data.crags}
                                    findMatch={findMatch}
                                />

                                <button
                                    className="bg-pink-400 rounded-full p-4 text-cyan-700 tracking-wider shadow-2xl border-2 border-fuchsia-400 font-extrabold"
                                    type="button"
                                    name='add-data'
                                    value='Add Route'
                                    onClick={handleSubmit}
                                    disabled={!routeInput.route_name.length || !user.user._id}
                                >Add Route</button>
                            </>
                        }
                    </form>
                }
            </div>
        </CollapsibleComponent>
    )
}
