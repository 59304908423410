import { formatGrade } from "../GradeScaleSelection";
import { useContext } from 'react';
import { GradeContext } from 'src/Context';
import { AddDataComponent, DisplayValue } from "./AddDataComponent";


export const GradeEntry = ({
    handleChange,
    value,
    fieldName,
}) => {
    const [selectedScale, _] = useContext(GradeContext)

    return (

        <AddDataComponent title='Grade:'>

            <p>Use the australian scale ...</p>

            <input
                className='w-4/5 p-2 px-5 rounded-md text-center bg-pink-50'
                type='number'
                name={fieldName}
                required={true}
                value={value}
                onChange={e => handleChange({ name: fieldName, value: e.target.valueAsNumber })}//formatGrade(e.target.valueAsNumber, {scale:'ewbank'})})}//handleChange(e.target)}//
                min='15'
                max='40'
                step='1'
            />
            <p>In the {selectedScale} grading scale:</p>
            <DisplayValue fieldName={fieldName} value={value} handleChange={handleChange} >
                {value && <h4 className="w-full rounded-2xl bg-pink-200 font-semibold p-3 text-xl text-center">{formatGrade(value, selectedScale)}</h4>}
            </DisplayValue>

        </AddDataComponent>
    )
};