import DrawMap from './DrawMap';
import { geoNaturalEarth1, brush, select } from 'd3';
import './Map.css';
import { useRef, useState, useEffect, useCallback, memo, useContext } from 'react';
import { DataContext } from 'src/Context';



const mapWidth = 1000;
const mapHeight = 500;

function Map({
    func = null,
    mode = 'none',
    selectedAreas = [],
}) {
    const [data, _] = useContext(DataContext);
    const projection = (geoNaturalEarth1().center([0, 0]));

    // get input logic
    const [selectedCoord, setSelectedCoord] = useState({ x: 0, y: 0 });
    const mapRef = useRef();
    const [mapXY, setMapXY] = useState();

    function setLatLong(d) {
        updateSelected(
            d.longitude,
            d.latitude,
            d.country,
            func,
            projection,
            setSelectedCoord,
            d.crag_name,
            d._id
        );
    }

    const getPosition = () => {
        const x = mapRef.current.offsetLeft;
        const y = mapRef.current.offsetTop;
        const viewWidth = mapRef.current.offsetWidth
        const viewHeight = mapRef.current.offsetHeight
        setMapXY({ x: x, y: y, viewWidth: viewWidth, viewHeight: viewHeight });
    }

    const handleGetCapture = (e) => {
        getPosition();
        if (mapXY) {
            captureLatLong(e, projection, func, setSelectedCoord, mapXY)
        }
    }

    // filtering logic
    const handleBrushing = useCallback((selection) => {
        if (selection && mode === 'filter') {
            const filteredCrags = selection ? data.crags.filter(d => {
                const [x0, y0] = projection.invert([selection[0][0], selection[0][1]])
                const [x1, y1] = projection.invert([selection[1][0], selection[1][1]])
                return d.latitude < y0
                    && d.latitude > y1
                    && d.longitude > x0
                    && d.longitude < x1;
            }) : null;
            func(filteredCrags.map(d => d._id))
        }
    }, [data.crags, mode, projection, func])

    const brushRef = useRef();
    useEffect(() => {
        const br = brush().extent([[0, 0], [mapWidth, mapHeight]]);
        br(select(brushRef.current));
        br.on('brush end', (event) => {
            handleBrushing(event.selection)
        });
    }, [handleBrushing])

    function addOneFilter(d) {
        if (isSelected(d, selectedAreas)) {
            func(state => state.filter(i => i !== d._id))
        } else func(state => [...state, d._id])
    }
    if ( !data.crags.length) {
        return <pre>No  Crag  data...</pre>
    }


    return (
        <div
            className='w-full items-center justify-center rounded-full'
            ref={mapRef}
        >
            <svg
                className='Map'
                height={mapHeight}
                width={mapWidth}
                viewBox={`0 0 ${mapWidth} ${(mapHeight)}`}
                onPointerUpCapture={e => mode === 'get' ? handleGetCapture(e)
                    : console.log(e)}
            >
                <DrawMap
                    projection={projection}
                    handleSelect={mode === "get" ? setLatLong : addOneFilter}
                    mode={mode}
                    brushRef={brushRef}
                    selectedAreas={selectedAreas}
                />
                {(mode === 'get' && selectedCoord.x) &&
                    <circle
                        className='selected-coord'
                        cx={selectedCoord.x}
                        cy={selectedCoord.y}
                    />
                }
            </svg>
        </div>
    )
}

export function isSelected(d, selectedAreas) {
    for (let i = 0; i < selectedAreas.length; i++) {
        if (d._id === selectedAreas[i]) return true;
    } return false
}

export function captureLatLong(e, projection, func, setSelectedCoord, mapXY) {
    // first take top left coords of map in page from capture coords
    // then div by width of map view to get percentage 
    // then times by drawn width to get the ref to the projected coords
    const xPos = (e.pageX - mapXY.x) / mapXY.viewWidth;
    const xReal = xPos * mapWidth;
    const yPos = (e.pageY - mapXY.y) / mapXY.viewHeight
    const yReal = yPos * mapHeight
    const [long, lat] = projection.invert([xReal, yReal])
    const country = e.target.id === "Israel" ? "Palestine" : e.target.id;
    updateSelected(long, lat, country, func, projection, setSelectedCoord);
}

export function updateSelected(long, lat, country, func, projection, setSelectedCoord, cragName = "", id = null) {
    if (cragName && id) {
        console.log(cragName, id)
        func({ name: 'crag_name', value: cragName, matchFound: id })
    } else {
        func({ name: '_id', value: id })
    }
    func({ name: 'longitude', value: long })
    func({ name: 'latitude', value: lat })
    func({ name: 'country', value: country })
    const [x, y] = projection([long, lat]);
    setSelectedCoord({ x: x, y: y })
}


export default memo(Map);
    // help from https://www.kindacode.com/article/react-get-the-position-x-y-of-an-element/