import { useReducer } from "react";
import { BiHide } from "react-icons/bi";
import { TbLayoutNavbarExpand } from 'react-icons/tb';
import { ShowContext } from "src/Context";

export const CollapseToggle = ({
    toggleShow,
    setToggleShow,
    showSideEffect,
    hideSideEffect,
    fieldName,

}) => {

    const toggleShowApp = () => {
        if (toggleShow) {
            setToggleShow();
            hideSideEffect();
        }
        else {
            setToggleShow();
            showSideEffect();
        }
    }

    return (
        <button
            className="toggle-show-app-button"
            type="button"
            name='toggle-show-app'
            value={toggleShow}
            onClick={toggleShowApp}
        >Show {fieldName} App</button>
    )
}

export const CollapsibleComponent = ({
    children,
    title,
    action = () => {return 0;},

}) => {
    // could useContext here so the child can minimise the div
    const [show, setShow] = useReducer(state => !state, false);

    return (
        <ShowContext.Provider value={show}>

            <div className="bg-teal-400 rounded-xl p-1 w-full sm:border-4 border-teal-200">
                <div
                    className="flex flex-row justify-between bg-gradient-to-tr from-teal-500 to-pink-500 rounded-lg p-3 items-center text-teal-100"
                    onClick={() => { setShow(); action() }}
                >
                    <h1 className="capitalize">{title}</h1>
                    {show ? <BiHide /> : <TbLayoutNavbarExpand />}
                </div>
                {show ? children : null}
            </div>
        </ShowContext.Provider>
    );
};