import { AddDataComponent, DisplayValue } from './AddDataComponent';
import './AddDataCSS.css';

const cruxGradeMarkers = [
    { val: '18', label: 'impossible' },
    { val: '17', label: '' },
    { val: '16', label: '' },
    { val: '15', label: 'hard' },
    { val: '14', label: '' },
    { val: '13', label: '' },
    { val: '12', label: 'difficult' },
    { val: '11', label: '' },
    { val: '10', label: '' },
    { val: '9', label: 'cruxy' },
    { val: '8', label: '' },
    { val: '7', label: '' },
    { val: '6', label: 'taxing' },
    { val: '5', label: '' },
    { val: '4', label: '' },
    { val: '3', label: 'moderate' },
    { val: '2', label: '' },
    { val: '1', label: '' },
    { val: '0', label: 'easy' },

]




export const CruxEntry = ({
    handleChange,
    value,
    fieldName,
}) => {
    const inputProps = {
        name: fieldName,
        value: value,
        onChange: (e) => cruxSliderChange({name:fieldName, value:e.target.valueAsNumber}),
        min: '0',
        max: '18',
        step: '0.1',
        required: true,

    }
    const cruxSliderChange = (target) => {
        if (target.value >= inputProps.min && target.value <= inputProps.max) {
            handleChange(target)
        }
    }

    return (
        <AddDataComponent title='Crux (v):' value={null}>
            <p>Slide the slider to the the difficulty of the hardest section of the route.</p>
            <p> The decimal system helps to differentiate hard for the grade and easy for the grade.</p>
            <div
                className='max-h-screen min-h-50 h-screen flex flex-row justify-evenly'
            // 'flex flex-row justify-center w-full h-lg max-h-lg min-h-xs'
            >
                <input
                    {...inputProps}
                    className="w-fit my-2 md:my-5 crux-slider "
                    type='range'
                    orient='vertical'
                    list='crux-markers'
                />
                <datalist
                    className='h-full inline-flex flex-col justify-evenly'
                    id='crux-markers'
                >
                    {cruxGradeMarkers.map(({ val, label }) => (
                        <option
                            className=' pl-5'
                            value={val}
                            key={val}
                            title={val}
                        >{`v${val}: ${label}`}
                        </option>


                    ))}
                </datalist>

            </div >
            <DisplayValue fieldName={fieldName} value={value} handleChange={cruxSliderChange} step={inputProps.step}>
                <input
                    className="w-full rounded-2xl bg-pink-200 font-semibold p-3 text-xl text-center"
                    type='number'
                    {...inputProps}
                />
            </DisplayValue>

        </AddDataComponent>
    )
}
