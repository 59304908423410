import { CountryEntry } from "./CountryEntry";
import { AreaEntry } from "./AreaEntry";
import { CoordEntry } from "./CoordEntry";
import Map from "../Map/Map";
import { useCallback, useContext } from "react";
import { DataContext } from "src/Context";
import { CollapsibleComponent } from "../CollapseToggle";
import { AddDataComponent } from "./AddDataComponent";


export const CragEntry = ({
    cragInput,
    setCragInput,
    toggleEditCrag,
    setToggleEditCrag,
    cragData,
    findMatch,
}) => {
    const [data, setData] = useContext(DataContext);

    const handleCragChange = useCallback((props) => {
        let { name, value, matchFound } = props
        setCragInput(
            (currentCragInput) => {
                return {
                    ...currentCragInput,
                    [name]: value,
                }
            }

        );
        if (matchFound) {
            let matched = findMatch(matchFound, cragData);
            if (matched) {
                setCragInput(matched)
                setToggleEditCrag(false)
            }
        }
    }, [cragData])

    return (
        <CollapsibleComponent title={`Area: ${cragInput.crag_name ? cragInput.crag_name : 'No area input'}`} >
            {!cragInput._id || toggleEditCrag ?
                // we have to ask for location
                <AddDataComponent value={`Current Selection: ${cragInput.crag_name}, ${cragInput.country}`}>
                    {!cragInput._id && <h3 className="add-data-app-header desc">Area not yet in our database...</h3>}
                    <p className="text-sm text-justify p-3">we dont really care about individual crags, we just want general areas, i.e. if there is a guidebook for it...</p>
                    <AreaEntry
                        handleChange={handleCragChange}
                        value={cragInput.crag_name}
                    />
                    {!cragInput.name || toggleEditCrag ?
                        <div className="w-full gap-2 flex flex-col">
                            <CountryEntry
                                handleChange={handleCragChange}
                                value={cragInput.country}
                                fieldName={'country'}
                            />
                            <CoordEntry
                                handleChange={handleCragChange}
                                fieldName={'latitude'}
                                value={cragInput.latitude}
                            />
                            <CoordEntry
                                handleChange={handleCragChange}
                                fieldName={'longitude'}
                                value={cragInput.longitude}
                            />
                            <CollapsibleComponent title={'Use Map'} >
                                <Map
                                    func={handleCragChange}
                                    mode='get'
                                    autofocus={true}
                                />
                            </CollapsibleComponent>
                        </div>
                        :
                        //  we already know country
                        <div>{cragInput.country}</div>
                    }
                </AddDataComponent>
                :
                // we already know location
                <AddDataComponent>
                    <h3 className="font-semibold">Area:</h3>
                    <h4 className="capitalize">{cragInput.crag_name},</h4>
                    <h4 className="capitalize">{cragInput.country}.</h4>
                    <button
                        type='button'
                        className="bg-pink-500 px-5 rounded-full"
                        name='toggle-edit-crag'
                        value={toggleEditCrag}
                        onClick={setToggleEditCrag}
                    >Edit Crag</button>
                </AddDataComponent>
            }
        </CollapsibleComponent>
    )
};