import { useUser } from "src/hooks";
import { BiLogIn, BiLogOut } from 'react-icons/bi';
import { CgProfile, CgMenuBoxed } from 'react-icons/cg';
import { GiWeightLiftingUp } from 'react-icons/gi';
import { AiOutlineFileProtect } from 'react-icons/ai';
import { useContext, useReducer } from "react";
// import { MdDarkMode } from 'react-icons/md';
import { UserContext } from "src/Context";

const NavBar = ({
    setRegistrationToggle,
    setLoginToggle,
}: any) => {
    // const [hideMenu, setHideMenu] = useReducer(state => !state, true)
    // const [darkMode, setDarkMode] = useReducer(state => !state, true)
    const [user, setUser] = useContext(UserContext);

    const { logoutStorage } = useUser();
    function handleLogout(e: any) {
        e.preventDefault();
        console.log('logging out')
        logoutStorage();
        setUser(null);
    }

    const NavLinks = [
        {
            label: 'Login',
            onClick: () => setLoginToggle(true),
            icon: <BiLogIn />,
            showConditions: !user,
        },
        {
            label: 'Log Out',
            onClick: (e: any) => handleLogout(e),
            icon: <BiLogOut />,
            showConditions: user,
        },
        {
            label: 'Register',
            onClick: () => setRegistrationToggle(true),
            icon: <AiOutlineFileProtect />,
            showConditions: !user,
        },
        // {
        //     label: 'Resistance',
        //     onClick: () => console.log('res'),//'/resistances.json',
        //     icon: <GiWeightLiftingUp />,
        //     showConditions: true,
        // },
        {
            label: user ? user.user.first_name : 'hello',
            onClick: () => console.log(user),
            icon: <CgProfile />,
            showConditions: user,
        },
        // {
        //     label: 'Menu',
        //     onClick: () => setHideMenu(),
        //     icon: <CgMenuBoxed />,
        //     showConditions: hideMenu,
        // },
        // {
        //     label: 'Dark Mode',
        //     onClick: () => setDarkMode(),
        //     icon: <MdDarkMode />,
        //     showConditions: true,
        // },
    ];

    return (
        <nav className="flex flex-row items-center justify-between bg-gradient-to-br from-teal-600 to-teal-500 rounded-b-3xl p-6 ">
            <div className="flex items-center flex-shrink-0 text-teal-100 mr-6 gap-2">
                <img src='/logo.png' className="h-20 w-30" alt='logo'></img>
                <span className="font-semibold text-3xl tracking-tight hidden sm:inline-block">Climb WIRD</span>
                <span className="font-semibold text-3xl tracking-tight hidden md:inline-block"> - Grade Comparision</span>
            </div>

            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                <div className="text-sm lg:flex-grow flex flex-row justify-end gap-2">
                    {NavLinks.map(({ label, onClick, icon, showConditions }, i) =>
                        <button onClick={onClick}
                            className={`p-2 flex flex-row rounded-lg items-center gap-2 text-teal-100 bg-gradient-to-tr from-pink-300 to-teal-600
                                ${showConditions ? 'flex' : 'hidden'}`}
                            key={i}>
                            {icon}
                            <h1 className="hidden md:flex text-lg">
                                {label}
                            </h1>
                        </button>
                    )}
                </div>
            </div>
        </nav>
    )
}

export default NavBar


