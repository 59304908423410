import { createContext } from "react";
import { gradeScaleOption } from "./components/GradeScaleSelection";

export const UserContext = createContext<UserContext>([undefined, null]);
export const DataContext = createContext<DataContextType | [null, null]>([]);
export const GradeContext = createContext<GradeContextType>([undefined, null]);
export const FilteredDataContext = createContext<FilteredDataContextType | null>(null);
export const HoveredContext = createContext<HoveredContextType | null>(null);
export const TopoContext = createContext<any | null>(null);
export const ShowContext = createContext<Boolean>(false);
export const EditRouteContext = createContext<EditRouteContextType | [null, null]>([null,null]);
export const RegressionContext = createContext<RegressionContextType | null>(null);
export const DataVarsContext = createContext<any>(null);


export type RegressionContextType = RouteData[] | null;
export interface User {
    apiKey: string;
    user: UserData;
    userID?: string;
    authID?: string;
};
export interface UserData {
    _id: string;
    first_name: string;
    last_name: string;
    verified: boolean;
    pro: boolean;
};
export interface apiDump {
    routes?: RouteData[],
    route_logs?: RouteLog[],
    users?: Object[],
    crags?: Object[],
};
export type GradeContextType = [
    selectedScale: gradeScaleOption | undefined,
    setGradeScale: any | null,
];
export type DataContextType = [
    data?: apiDump,
    setData?: any,
];
export type FilteredDataContextType = [
    filteredRoutes: Object[] | undefined,
    setFilteredRoutes: any | undefined,
];
export type EditRouteContextType = [
    editRoute: RouteLog | null,
    setEditRoute: any | null,
]
export type UserContext = [
    user: User | undefined,
    setUser: any,
];

export type HoveredContextType = [
    hoveredValue: Object | null,
    setHoveredValue: any,
];

export type RouteLog = {
    _id: string,
    route_id: string,
    user_id: string,
    comment: string,
    crux_opinion: number,
    date: number,
    grade_opinion: number,
    res_opinion: number,
};

export type RouteData = {
    _id: string,
    route_name: string,
    average_grade: number,
    average_crux: number,
    average_res: number,
    crag_id: string,

}
