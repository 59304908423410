
export const FilterDropDown = ({
    filterOptions,
    menuID,
    onSelectedFilterChange,
    selectedFilter,

}) => {
    return (
        <div className="rounded-full bg-teal-300">
            <select
                className="rounded-xl shadow-md bg-inherit p-2 text-pink-600"
                id={menuID}
                onChange={e => onSelectedFilterChange(e.target.value)}
                defaultValue={selectedFilter}
            >
                {filterOptions.map(({ value, label, disabled }) => (
                    <option
                        value={value}
                        key={value}
                        disabled={disabled}
                    >
                        {label}
                    </option>
                ))}
            </select>
        </div>
    )
};

