import { FilterDropDown } from './FilterComponents';
import { DoubleSlider } from './DoubleRangeSlider';
import { MultiFilter } from './MultiFilter';
import { CheckBox } from './CheckBoxFilter';
import { useState, useMemo, useContext, useEffect } from 'react';
import { max, min } from 'd3';
import './FilterApp.css';
import { userFilter, applyFilter, countryFilter, binaryFilter, ratingFilter } from './Filters';
import Map from '../Map/Map';
import { DataContext, FilteredDataContext } from 'src/Context';
import { CollapsibleComponent } from "../CollapseToggle";


const filterOptions = [
    { value: 'default', label: 'Choose Filters', disabled: false },
    { value: 'all', label: 'All Data', disabled: false },
    { value: 'user', label: 'User', disabled: false, },
    { value: 'pros', label: 'Pro Data', disabled: false, },
    { value: 'verified', label: 'Verified Users', disabled: false, },
    { value: 'continent', label: 'Continent', disabled: true, },
    { value: 'countries', label: 'Country', disabled: false, },
    { value: 'areas', label: 'Area', disabled: false, },
    { value: 'grade', label: 'Grade', disabled: false, },
    { value: 'resistance', label: 'Resistance', disabled: false, },
    { value: 'crux', label: 'Crux Grade', disabled: false, },
    { value: 'map', label: 'Map', disabled: false, },
];


const initialFilter = 'all';
const initialCountryFilter = [];
const initialAreaFilter = [];
const initialUserFilter = [];
const initialProFilter = false;
const initialVerifiedFilter = false;

const gradeValue = d => d.average_grade;
const resValue = d => d.average_res;
const cruxValue = d => d.average_crux;


// handle case of filters not returning result ???
export const FilterApp = () => {

    const [data, setData] = useContext(DataContext);
    const [_, setFilteredRoutes] = useContext(FilteredDataContext);

    const dataGradeRange = { 'min': min(data.routes, gradeValue), 'max': max(data.routes, gradeValue) };
    const dataResRange = { 'min': min(data.routes, resValue), 'max': max(data.routes, resValue) };
    const dataCruxRange = { 'min': min(data.routes, cruxValue), 'max': max(data.routes, cruxValue) };

    const [selectedFilterValue, setSelectedFilterValue] = useState(initialFilter);
    const [selectedCountries, setSelectedCountries] = useState(initialCountryFilter);
    const [selectedUsers, setSelectedUsers] = useState(initialUserFilter);
    const [selectedAreas, setSelectedAreas] = useState(initialAreaFilter);
    const [proFilter, setProFilter] = useState(initialProFilter);
    const [verifiedFilter, setVerifiedFilter] = useState(initialVerifiedFilter);
    const [selectedGradeRange, setSelectedGradeRange] = useState(dataGradeRange);
    const [selectedResistanceRange, setSelectedResistanceRange] = useState(dataResRange);
    const [selectedCruxRange, setSelectedCruxRange] = useState(dataCruxRange);

    let filteredData = useMemo(() => data.routes, [data.routes]);

    const checkGradeMin = () => selectedGradeRange.min !== dataGradeRange.min;
    const checkGradeMax = () => selectedGradeRange.max !== dataGradeRange.max;
    const checkGrade = () => checkGradeMin() || checkGradeMax();

    const checkResMin = () => selectedResistanceRange.min !== dataResRange.min;
    const checkResMax = () => selectedResistanceRange.max !== dataResRange.max;
    const checkRes = () => checkResMin() || checkResMax();

    const checkCruxMin = () => selectedCruxRange.min !== dataCruxRange.min;
    const checkCruxMax = () => selectedCruxRange.max !== dataCruxRange.max;
    const checkCrux = () => checkCruxMin() || checkCruxMax();

    const continent = (c) => ''

    let areaOptions = data.crags.map(d => {
        return { first_name: d.crag_name, last_name: d.country, _id: d._id }
    })
    const countriesInData = [...new Set(data.crags.map((d) => d.country))];
    let countryOptions =
        // [...new Set(data.crags.filter(d => {
        // return { first_name: d.country, last_name: continent(d.country), _id: d.country }
        // }))]
        countriesInData.map(country => {
            return { first_name: country, last_name: continent(country), _id: country }
        })
    // filteredData = useMemo(() => {
    useEffect(() => {
        console.log('filtering data');
        if (selectedFilterValue !== 'all') {
            // let filteredData = []
            filteredData = (userFilter({ selectedUsers: selectedUsers, routeLogData: data.route_logs, filteredData: filteredData }))
            // area filter
            filteredData = (applyFilter({ selected: selectedAreas, filteredData: filteredData, filterField: 'crag_id' }))
            // continent filter
            // country filter
            filteredData = (countryFilter({ selected: selectedCountries, dataSet: data.crags, filterField: 'country', filteredData: filteredData }))
            // pro filter
            filteredData = (binaryFilter({ filter: proFilter, primaryDataSet: data.users, secondaryDataSet: data.route_logs, filterField: 'pro', filteredData: filteredData }))
            // verified filter
            filteredData = (binaryFilter({ filter: verifiedFilter, primaryDataSet: data.users, secondaryDataSet: data.route_logs, filterField: 'verified', filteredData: filteredData }))
            // grade filter
            filteredData = ratingFilter({ check: checkGrade, filteredData: filteredData, filterField: 'average_grade', selectedRange: selectedGradeRange })
            // resistance filter
            filteredData = ratingFilter({ check: checkRes, filteredData: filteredData, filterField: 'average_res', selectedRange: selectedResistanceRange })
            // crux filter
            filteredData = ratingFilter({ check: checkCrux, filteredData: filteredData, filterField: 'average_crux', selectedRange: selectedCruxRange })
        } else {
            // set all filters to default?s
            console.log('no filters to apply')
        }
        setFilteredRoutes(filteredData)

    }, [selectedFilterValue,
        selectedUsers,
        selectedAreas,
        selectedCountries,
        proFilter,
        verifiedFilter,
        selectedGradeRange,
        selectedResistanceRange,
        selectedCruxRange,
        data.crags,
        filteredData,])

    if (!filteredData.length) {
        return <pre>Loading data...</pre>
    }
    // dictionary of filters that use a slider
    const sliders = [
        {
            'checkFunction': checkGrade,
            props: ({
                'fieldName': 'grade',
                'description': "Grade Range",
                'selectedRange': selectedGradeRange,
                'setSelectedRange': setSelectedGradeRange,
                'dataRange': dataGradeRange,
                'step': 1,
                key: 'grade'
            })
        },
        {
            'checkFunction': checkRes,
            props: ({

                'fieldName': 'resistance',
                'description': "Resistance Range",
                'selectedRange': selectedResistanceRange,
                'setSelectedRange': setSelectedResistanceRange,
                'dataRange': dataResRange,
                'step': 1,
                key: 'res'
            })
        },
        {
            'checkFunction': checkCrux,
            props: ({

                'fieldName': 'crux',
                'description': "Crux Range",
                'selectedRange': selectedCruxRange,
                'setSelectedRange': setSelectedCruxRange,
                'dataRange': dataCruxRange,
                'step': 0.1,
                key: 'crux'
            })
        },
    ]

    const multiFilters = [
        {
            'fieldName': 'user',
            'setSelected': setSelectedUsers,
            'selected': selectedUsers,
            'data': data.users,
        },
        {
            'fieldName': 'countries',
            'setSelected': setSelectedCountries,
            'selected': selectedCountries,
            'data': countryOptions,
        },
        {
            'fieldName': 'areas',
            'setSelected': setSelectedAreas,
            'selected': selectedAreas,
            'data': areaOptions,
        },
    ]

    const checkFilters = [
        {
            'fieldName': 'pros',
            'setFilter': setProFilter,
            'filter': proFilter,
            'description': 'Pro Data Only'
        },
        {
            'fieldName': 'verified',
            'setFilter': setVerifiedFilter,
            'filter': verifiedFilter,
            'description': 'Verified User Data Only'
        },
    ]

    return (
        <CollapsibleComponent title={
            <FilterDropDown
                filterOptions={filterOptions}
                menuID='filter-dropdown'
                onSelectedFilterChange={setSelectedFilterValue}
                selectedFilter={selectedFilterValue}
            />} >

            <div className='flex flex-row flex-wrap w-full items-center justify-evenly'>


                {selectedFilterValue !== 'all' ?
                    <>
                        {selectedFilterValue === 'map' ?
                            <Map
                                mode='filter'
                                func={setSelectedAreas}
                                selectedAreas={selectedAreas}
                            />
                            : <></>}
                        {multiFilters.map((f) => {
                            if (f.selected.length ||
                                selectedFilterValue === f.fieldName) {
                                return (
                                    <MultiFilter
                                        fieldName={f.fieldName}
                                        setSelected={f.setSelected}
                                        selected={f.selected}
                                        data={f.data}
                                        key={f.fieldName}
                                    />
                                )
                            } return false;
                        })}

                        {checkFilters.map((f) => {
                            if (selectedFilterValue === f.fieldName ||
                                f.filter) {
                                return (
                                    <CheckBox
                                        setFilter={f.setFilter}
                                        filter={f.filter}
                                        fieldName={f.fieldName}
                                        description={f.description}
                                        key={f.fieldName}
                                    />
                                )
                            } return false;
                        })}

                        {sliders.map((f) => {
                            if (
                                selectedFilterValue === f.props.fieldName ||
                                f.checkFunction()) {
                                return (
                                    <DoubleSlider
                                        {...f.props}
                                    />
                                )
                            } return false;
                        })}
                    </> : null}
            </div>
        </CollapsibleComponent>

    )
};
