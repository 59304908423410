import { geoGraticule, geoPath } from 'd3';
import { memo, useContext } from 'react'
import { DataContext, TopoContext } from 'src/Context';
import { isSelected } from './Map';


function DrawMap({
    projection,
    handleSelect,
    mode,
    brushRef,
    selectedAreas = [],
}) {
    const [data, _] = useContext(DataContext);
    const topology = useContext(TopoContext);
    const pathGenerator = geoPath(projection);
    const graticule = geoGraticule();
    

    return (
        <g>
            <path className="sphere" d={pathGenerator({ type: 'Sphere' })} />
            <path className="graticules" d={pathGenerator(graticule())} />
            {topology.countries.features.map((feature, i) => {
                return (<path className="land" key={i} id={feature.properties.name} d={pathGenerator(feature)}>
                    <title>{feature.properties.name}</title>
                </path>
                )
            })}

            <path className="interiors" d={pathGenerator(topology.interiors)} />

            {mode === 'filter' && <g ref={brushRef} />}

            {data.crags.map((d, i) => {
                const [x, y] = projection([+d.longitude, +d.latitude]);

                return <circle
                    cx={x}
                    cy={y}
                    r={15}
                    onClick={() => handleSelect(d)}
                    key={i}
                    className={isSelected(d, selectedAreas) ? 'selected-coord' : 'crag-coord'}
                >
                    <title>{d.crag_name}</title>
                </circle>
            })}
        </g>
    )
}
export default memo(DrawMap);
            // {/* {topology.land.features.map(feature => ( */}
