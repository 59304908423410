import { useContext, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { EditDataApp } from "./components/AddDataApp/EditDataApp";
import { CollapsibleComponent } from "./components/CollapseToggle";
import { Chart } from "./components/ScatterPlot/Chart";
import { DataContext, DataVarsContext, EditRouteContext, RegressionContext, UserContext } from "./Context";


export const MyRoutes = () => {
    const [user, setUser] = useContext(UserContext);
    const [data, setData] = useContext(DataContext);
    const [editRoute, setEditRoute] = useState(null);
    const [gradeData, setGradeData] = useState(null);

    const myRoutes = data && data.route_logs && user ? data.route_logs.filter((r) => r.user_id === user.user._id) : null

    const dataVars = {
        xValue: d => d.res_opinion,
        xField: 'res_opinion',
        yValue: d => d.crux_opinion,
        yField: 'crux_opinion',
        pointValue: d => d.grade_opinion,
        pointName: d => d.route_id,
    };

    const editEntry = (r) => {
        setEditRoute(r)
        // console.log(r)
    }

    const deleteEntry = (r) => {
        console.log(r)
        alert(`Are you sure you want to delete your entry for ${editRoute.route_id}?`)
    }
    const handleHover = e => {
        // console.log(e);
        // myRoutes.map(d => console.log(dataVars.pointValue(e)))
        setGradeData(myRoutes.filter(d =>
            dataVars.pointValue(d) === dataVars.pointValue(e)
        ));

    }

    // const handleClick = e => console.log(e);

    return (
        <CollapsibleComponent title='MyRoutes'>
            <div className="w-full p-5 text-pink-700">

                <table className="w-full items-stretch p-5 ">
                    <thead className="font-bold"><td>Route ID</td><td>Edit</td><td>Delete</td></thead>
                    <tbody>
                        {myRoutes ? myRoutes.map((r, i) => {
                            return (
                                <tr key={i} className='border-b-2 border-teal-200'>
                                    <td>{r.route_id}</td>
                                    <td onClick={() => editEntry(r)}><BiEditAlt /></td>
                                    <td onClick={() => deleteEntry(r)}><RiDeleteBinLine /></td>
                                </tr>
                            )
                        }
                        ) : <h1>no routes</h1>}
                    </tbody>
                </table>
            </div>
            <EditRouteContext.Provider value={[editRoute, setEditRoute]}>
                {editRoute !== null && <EditDataApp />}
            </EditRouteContext.Provider>
            {/* <RegressionContext.Provider value={gradeData}> */}
            <DataVarsContext.Provider value={dataVars}>
                <Chart
                    xValue={d => d.res_opinion}
                    yValue={d => d.crux_opinion}
                    pointValue={d => d.grade_opinion}
                    pointName={d => d.route_id}
                    filteredData={myRoutes}
                    onHover={handleHover}
                    gradeData={gradeData}
                />
            </DataVarsContext.Provider>
            {/* </RegressionContext.Provider> */}
        </CollapsibleComponent>
    )
}