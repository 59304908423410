import { useContext } from "react"
import { GradeContext } from "src/Context"

export type gradeScaleOption = "french" | "ewbank" | "yds"
export const gradeScaleOptions: gradeScaleOption[] = ['french', 'ewbank', 'yds',]
export const defaultGradeScale: gradeScaleOption = 'french'

export interface GradeScaleSelectionProps {
    setGradeScale: (value: gradeScaleOption) => void,
    selectedScale: gradeScaleOption
};
export interface GradeOptionsSelectProps {
    class: string,
    label: string,

}

const gradeList = [
    {
        "ewbank": 15,
        "yds": "5.8",
        "french": "5-"
    },
    {
        "ewbank": 16,
        "yds": "5.9-",
        "french": "5+"
    },
    {
        "ewbank": 17,
        "yds": "5.9+",
        "french": "6a"
    },
    {
        "ewbank": 18,
        "yds": "5.10-",
        "french": "6a+"
    },
    {
        "ewbank": 19,
        "yds": "5.10",
        "french": "6b"
    },
    {
        "ewbank": 20,
        "yds": "5.10+",
        "french": "6b+"
    },
    {
        "ewbank": 21,
        "yds": "5.11-",
        "french": "6c"
    },
    {
        "ewbank": 22,
        "yds": "5.11",
        "french": "6c+"
    },
    {
        "ewbank": 23,
        "yds": "5.11+",
        "french": "7a"
    },
    {
        "ewbank": 24,
        "yds": "5.12a",
        "french": "7a+"
    },
    {
        "ewbank": 25,
        "yds": "5.12b",
        "french": "7b"
    },
    {
        "ewbank": 26,
        "yds": "5.12c",
        "french": "7b+"
    },
    {
        "ewbank": 27,
        "yds": "5.12d",
        "french": "7c"
    },
    {
        "ewbank": 28,
        "yds": "5.13a",
        "french": "7c+"
    },
    {
        "ewbank": 29,
        "yds": "5.13b",
        "french": "8a"
    },
    {
        "ewbank": 30,
        "yds": "5.13c",
        "french": "8a+"
    },
    {
        "ewbank": 31,
        "yds": "5.13d",
        "french": "8b"
    },
    {
        "ewbank": 32,
        "yds": "5.14a",
        "french": "8b+"
    },
    {
        "ewbank": 33,
        "yds": "5.14b",
        "french": "8c"
    },
    {
        "ewbank": 34,
        "yds": "5.14c",
        "french": "8c+"
    },
    {
        "ewbank": 35,
        "yds": "5.14d",
        "french": "9a"
    },
    {
        "ewbank": 36,
        "yds": "5.15a",
        "french": "9a+"
    },
    {
        "ewbank": 37,
        "yds": "5.15b",
        "french": "9b"
    },
    {
        "ewbank": 38,
        "yds": "5.15c",
        "french": "9b+"
    },
    {
        "ewbank": 39,
        "yds": "5.15d",
        "french": "9c"
    },
    {
        "ewbank": 40,
        "yds": "5.16a",
        "french": "9c+"
    }
]

export const formatGrade = (
    ewbankGrade: number,
    selectedScale: gradeScaleOption,
) => {

    if (!ewbankGrade) return 0
    if (!selectedScale) return ewbankGrade
    for (let i = 0; i < gradeList.length; i++) {
        if (gradeList[i].ewbank === ewbankGrade) {
            return gradeList[i][`${selectedScale}`];
        }
    }

}


export const GradeScaleSelection = () => {
    const [selectedScale, setGradeScale] = useContext(GradeContext)

    const handleChange = (value: any) => {
        setGradeScale(value)
    }

    return (
        <div className='flex flex-row flex-wrap items-center justify-start w-auto h-auto m-3 text-md '>
            <select
                className='h-fit ml-0  bg-slate-200 rounded-md'
                onChange={e => handleChange(e.target.value)}
                value={selectedScale}
            >
                {gradeScaleOptions.map((scale) =>
                    <option
                        className="rounded-md"
                        value={scale}
                        key={scale}
                    >{scale}</option>)
                }
            </select>
        </div>
    )
}
