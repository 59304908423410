
export const CheckBox = ({
    setFilter,
    filter,
    fieldName,
    description,
}) => {
    const toggleFilter = () => {
        if (filter) { setFilter(false) }
        else { setFilter(true) }
    };

    return (
        <div
            className="rounded-full bg-gradient-to-r from-transparent to-teal-300 flex flex-row items-center justify-end px-6 w-full min-w-xs max-w-sm gap-5">
            <label
                className="w-auto text-end font-semibold text-pink-600"
                htmlFor={fieldName}
            >{description}</label>
            <input
                className="w-auto p-5"
                type='checkbox'
                checked={filter}
                name={fieldName}
                onChange={toggleFilter}
            />

        </div>
    )
}
