import { QueryClient, QueryClientProvider } from 'react-query'
import React, { useReducer, useState } from 'react';
import { useRetrieveFromDatabase, useTopology, useUser } from './hooks';
import './App.css';
import { FilterApp } from './components/FilterApp/FilterApp';
import { NewDataApp } from './components/AddDataApp/NewDataApp';
import NavBar from './components/NavBar';
import { BodyText } from './BodyText';
import { SelectedLabel } from './components/SelectedLabel/SelectedLabel';
import { gradeScaleOption, defaultGradeScale } from './components/GradeScaleSelection';
import { RegisterApp } from './components/Register/RegisterApp';
import { LoginApp } from './components/Login/LoginApp';
import { UserContext, DataContext, apiDump, GradeContext, FilteredDataContext, HoveredContext, TopoContext } from './Context';
import { ScatterPlot } from './components/ScatterPlot/ScatterV2';
import { FaSpinner } from 'react-icons/fa';
import { Footer } from './Footer';
import { MyRoutes } from './MyRoutes';
import { LineChart } from './components/ScatterPlot/LineChart';
import { CollapsibleComponent } from './components/CollapseToggle';

const queryClient = new QueryClient()



function App() {

  const [data, setData] = useState<apiDump>({});
  useRetrieveFromDatabase('/dump', setData);
  const { user, setUser } = useUser();
  // this could be in user obj aswell
  const [selectedScale, setGradeScale] = useState<gradeScaleOption>(defaultGradeScale)
  const [registrationToggle, setRegistrationToggle] = useReducer(state => !state, false);
  const [loginToggle, setLoginToggle] = useReducer(state => !state, false);
  const [hoveredValue, setHoveredValue] = useState(null);
  const [filteredRoutes, setFilteredRoutes] = useState<Object[] | undefined>(data.routes);
  const topology = useTopology();

  return (
    <QueryClientProvider client={queryClient}>
      <GradeContext.Provider value={[selectedScale, setGradeScale]}>
        <UserContext.Provider value={[user ? user : undefined, setUser]}>
          <DataContext.Provider value={[data, setData]}>
            <main className="main-body bg-pink-100 flex gap-3 flex-col">

              <NavBar
                setRegistrationToggle={setRegistrationToggle}
                setLoginToggle={setLoginToggle}
              />
              {(registrationToggle && !user) &&
                <RegisterApp setRegistrationToggle={setRegistrationToggle} />}

              {(loginToggle && !user) &&
                <LoginApp setLoginToggle={setLoginToggle} />}

              <TopoContext.Provider value={topology}>
                {data && data.routes && data.crags ?
                  <FilteredDataContext.Provider value={[filteredRoutes, setFilteredRoutes]}>
                    <HoveredContext.Provider value={[hoveredValue, setHoveredValue]}>
                      {user && <MyRoutes />}

                      < FilterApp />

                      <ScatterPlot />
                      <CollapsibleComponent title='observed relationships'>
                        <LineChart
                          filteredData={data.routes}
                          // gradeData={gradeData}
                          onHover={console.log('hovered')} />
                      </CollapsibleComponent>

                      <SelectedLabel />

                    </HoveredContext.Provider>
                  </FilteredDataContext.Provider>
                  : <FaSpinner className='animate-spin w-full my-10 text-9xl fill-amber-500' />
                }
                {(data.crags && user) && <NewDataApp />}

              </TopoContext.Provider>
              <BodyText />
              <Footer />
            </main>
          </DataContext.Provider>
        </UserContext.Provider>
      </GradeContext.Provider>
    </QueryClientProvider >
  );
}

export default App;
