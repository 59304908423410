import { Search } from './AutoComplete'
import { useContext } from 'react';
import { DataContext } from "src/Context";
import { AddDataComponent } from './AddDataComponent';


export const RouteNameEntry = ({
    handleChange,
    value,
}) => {
    const [data, setData] = useContext(DataContext);

    return (
        <AddDataComponent title="Route Name">
            <p>Type the name of the route here, check spelling to avoid duplication.</p>
            <Search
                suggestions={data.routes}
                fieldName={'route_name'}
                value={value}
                handleChange={handleChange}//{e => handleChange(e.target)}
                placeholder={'Start typing to see suggestions...'}
                // autoFocus={true}
            />
        </AddDataComponent>
    )
}