import { useContext, useState } from "react";
import { DataContext, EditRouteContext, UserContext } from "src/Context";
import { CollapsibleComponent } from "../CollapseToggle";
import { AddDataComponent } from "./AddDataComponent";
import { CommentEntry } from "./CommentEntry";
import { CruxEntry } from "./CruxEntry";
import { GradeEntry } from "./GradeEntry";
import { ResistanceEntry } from "./ResistanceEntry";
import { handleAddDataSubmit } from "src/hooks";

export const EditDataApp = () => {
    const [data, setData] = useContext(DataContext);
    const [user, setUser] = useContext(UserContext);
    const [editRoute, setEditRoute] = useContext(EditRouteContext);
    // const [routeInput, setRouteInput] = useState(editRoute);


    const handleChange = (target) => {
        setEditRoute(
            (currentRouteInput) => {
                return {
                    ...currentRouteInput,
                    [target.name]: target.value,
                }
            }
        );
    }

    const updateRoute = (event) => {
        event.preventDefault();
        console.log(editRoute)
        handleAddDataSubmit({
            routeInput: editRoute,
            userID: user.user._id,
            cragInput: null,
            setData: setData,
            token: user.apiKey,
            mode: 'edit',
        });
        // setCragInput(defaultCragInput);
        // setRouteInput(defaultRouteInput);
    }

    return (
        <CollapsibleComponent title={`Edit: ${editRoute.route_id}`}>

            <AddDataComponent>
                <GradeEntry
                    handleChange={handleChange}
                    value={editRoute.grade_opinion}
                    fieldName={'grade_opinion'}
                />
                <CruxEntry
                    handleChange={handleChange}
                    value={editRoute.crux_opinion}
                    fieldName='crux_opinion'
                />
                <ResistanceEntry
                    handleChange={handleChange}
                    value={editRoute.res_opinion}
                    fieldName='res_opinion'
                />
                <CommentEntry
                    handleChange={handleChange}
                    value={editRoute.comment}
                    fieldName='comment'
                />
                <button className='bg-pink-400 rounded-full w-full p-5 text-xl shadow-2xl border-pink-200 border-2 text-cyan-600 font-semibold' onClick={updateRoute}>Update Route</button>
            </AddDataComponent>
        </CollapsibleComponent>

    )
}