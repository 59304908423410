import { DataCircle, giveLegendClassDef } from "./DataCircle";
import { formatGrade, GradeScaleSelection } from "../GradeScaleSelection";
import { useContext } from 'react';
import { GradeContext } from 'src/Context';

const padding = 20;


export const Legend = ({
    data,
    onHover,
    hoveredValue,
    pointValue,
}) => {
    const gradesInData = [...new Set(data.map((d) => (pointValue(d))))].sort();
    const [selectedScale, _] = useContext(GradeContext)

    return (
        <div className="w-full sm:w-auto flex-wrap justify-items-stretch flex sm:py-5 flex-row sm:flex-col bg-teal-50 rounded-3xl items-start mr-2 m-2 px-2 lg:gap-10">
            <GradeScaleSelection
            />
            {gradesInData.map((d, i) => {
                let classDef = giveLegendClassDef(hoveredValue, +d, pointValue)
                let valDef = { average_grade: d, _id: 0 }
                return (
                    // group with circle and label
                    <div
                        className={`${classDef} flex flex-row items-center justify-center w-fit sm:w-fit md:mx-2 md:gap-1`}
                        // transform={`translate(${padding},${padding}})`}
                        onMouseEnter={() => { onHover(valDef) }}
                        // onMouseOut={() => { onHover(null) }}
                        onPointerMove={() => { onHover(valDef) }}
                        key={`legend${d}`}
                    >
                        <svg width={padding * 2} height={padding * 2}
                            className=""
                            viewBox={`0 0 ${padding * 2} ${(padding * 2)}`}
                        >
                            <DataCircle
                                classDef={classDef}
                                onHover={onHover}
                                toolTip={formatGrade(d, selectedScale)}
                                value={valDef}
                                x={padding}
                                y={padding}
                            />
                        </svg>
                        <h2 className="text-center text-lg">
                            {formatGrade(d, selectedScale)}
                        </h2>
                    </div>
                )
            }
            )
            }
        </div>
    )
}

