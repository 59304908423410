import { loginUser } from 'src/hooks';
import { useContext, useState } from "react"
import { UserContext } from 'src/Context';
import { FaSpinner } from 'react-icons/fa';


export const LoginApp = ({ setLoginToggle }: any) => {

    const [ user, setUser] = useContext(UserContext);
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const inputFields = [
        {
            fieldName: username,
            type: 'text',
            onchange: setUsername,
            label: 'Username',
            required: true,
            autocapitalize: 'off',
        },
        {
            fieldName: password,
            type: 'password',
            onchange: setPassword,
            label: 'Password',
            required: true,
            autocapitalize: 'off',

        },
    ]
    const login = async () => {
        setLoading(true);
        const user = await loginUser({ 'username': username, 'password': password })
        if (user.user) {

            setUser(user)
            setLoginToggle();
        } else alert('failed login');
        setLoading(false);
    }
    if (loading) return <FaSpinner className='animate-spin w-full m-10 text-2xl fill-amber-500' />
    return (

        <form
            className="bg-opacity-50 bg-pink-300 rounded-3xl p-5 w-full flex flex-col justify-center items-center gap-2 text-pink-800"
            onSubmit={e => e.preventDefault()}>

            {inputFields.map(input =>
                <input
                    key={input.label}
                    className="max-w-xl w-full p-5 rounded-3xl"
                    value={input.fieldName}
                    onChange={e => input.onchange(e.target.value)}
                    type={input.type}
                    placeholder={`${input.label}${input.required ? "*" : ""}`}
                    required={input.required}
                    autoCapitalize={input.autocapitalize}
                />
            )}
            <button
                className="p-5 bg-cyan-500 rounded-full min-w-fit w-1/5 shadow-2xl"
                type='submit'
                onClick={login}
            >
                Login
            </button>
        </form>
    )
}