import { useContext, useMemo, useState } from 'react';
import { Legend } from './LegendV3';
import { DataContext, DataVarsContext, FilteredDataContext, HoveredContext, RegressionContext } from 'src/Context';
import { Chart } from './Chart';

const pointValue = d => d.average_grade;

export const ScatterPlot = () => {
    const [filteredRoutes, _] = useContext(FilteredDataContext);
    const [data, setData] = useContext(DataContext);
    const [hoveredValue, setHoveredValue] = useContext(HoveredContext);
    const [gradeData, setGradeData] = useState(null);
    const dataVars = {
        xValue: d => d.average_res,
        xField: 'average_res',
        yValue: d => d.average_crux,
        yField: 'average_crux',
        pointValue: d => d.average_grade,
        pointName: d => d.route_name,
    };

    let filteredData = useMemo(() =>
        filteredRoutes ? filteredRoutes : data.routes
        , [filteredRoutes]);

    const onHover = (value) => {
        setGradeData(filteredData.filter(d =>
            pointValue(d) === pointValue(value)));
        setHoveredValue(value)
    }


    return (
        <div className='flex justify-center flex-row-reverse flex-wrap items-start h-auto'>
            {/* add a grade legend */}
            {/* <RegressionContext.Provider value={gradeData}> */}
                <DataVarsContext.Provider value={dataVars}>
                    <Legend
                        data={filteredData}
                        onHover={onHover}
                        hoveredValue={hoveredValue}
                        pointValue={pointValue}
                    />
                    <Chart
                        filteredData={filteredData}
                        gradeData={gradeData}
                        onHover={onHover}
                    />
                </DataVarsContext.Provider>
            {/* </RegressionContext.Provider> */}
        </div>
    );
};

