import { ResistanceTickFormat } from "../FormatResistanceScale";

export const AxisBottom = ({
  xScale,
  innerHeight,
  tickOffset = 2,
  tickNumber,
}) => {
  // this function is a bit pointless now
  let resistanceInfo = ResistanceTickFormat();
  return (
    // xScale.ticks(tickNumber)
    xScale.ticks(tickNumber).map(tickValue => {

      let tickInfo = { name: 'name', description: 'description' }
      for (let i = 0; i < resistanceInfo.length; i++) {
        if ((tickValue >= resistanceInfo[i].range[0]) && (tickValue <= resistanceInfo[i].range[1])) {
          tickInfo = { name: resistanceInfo[i].name, description: resistanceInfo[i].description };
        }
      }

      return (
        // a group with each tick for tick in map
        <g
          key={tickValue}
          transform={`translate(${xScale(tickValue)},0)`}
        >
          {/* add a gridline */}
          <line y2={innerHeight} className="stroke-pink-500 opacity-40" />
          {/* add a label */}

          <text
            className='fill-pink-600 cursor-default text-xs'
            dy=".71em"
            transform={
              `translate(${tickOffset},
            ${innerHeight + tickOffset}) 
            rotate(90)`}
          // textLength='70px'//'30%'
          // wrap='true' if this existed
          >
            {/* tooltip text */}
            <title>
              {tickInfo.name}: {tickInfo.description}
            </title>
            {/* label text */}
            {tickInfo.name}
            {/* {tickFormat(tickValue).name} */}
          </text>
        </g>
      )
    }
    )
  )
}


  // ToDo: 
  //  wrap label text?






